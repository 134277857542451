import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["addNewTicket", "column"]
  static values = {
    productId: String,
    status: String,
    assigneeId: String,
    tagName: String,
    reporterId: String,
    ticketableId: String
  }

  connect() {
    console.log("Kanban controller connected")
  }

  dragStart(event) {
    // const ticketId = event.target.closest('[data-ticket-id]').dataset.ticketId
    const ticketId = event.currentTarget.dataset.ticketId

    event.dataTransfer.setData("text/plain", ticketId)
    event.target.classList.add("opacity-50")
    this.columnTargets.forEach(column => {
      column.classList.add("dragging-active")
    })
  }

  dragEnd(event) {
    event.target.classList.remove("opacity-50")
    this.columnTargets.forEach(column => {
      column.classList.remove("dragging-active")
    })
  }

  dragOver(event) {
    event.preventDefault()
    event.currentTarget.classList.add("bg-blue-100")
  }

  dragEnter(event) {
    event.preventDefault()
  }

  dragLeave(event) {
    event.currentTarget.classList.remove("bg-blue-100")
  }

  drop(event) {
    event.preventDefault()
    const targetColumnId = event.currentTarget.dataset.columnId
    const ticketId = event.dataTransfer.getData("text/plain")
    
    if (!ticketId) {
      console.error("Ticket ID not found")
      return
    }
    event.currentTarget.classList.remove("bg-blue-100")
    
    this.updateTicketStatus(ticketId, targetColumnId)
  }

  updateTicketStatus(ticketId, columnId) {
    const url = `/tickets/${ticketId}/update_status`
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content
    Turbo.visit(window.location.href, { action: "replace" })

    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({ status: columnId })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        // Todo: Add an effect that conveys success, eg a green border pulse animation
      } else {
        console.error("Failed to update ticket status")
      }
    })
    .catch(error => {
      console.error("Error:", error)
    })
  }

  createNewTicket(event) {
    event.preventDefault()
    console.log("createNewTicket")
    const status = event.currentTarget.dataset.status
    const columnId = event.currentTarget.dataset.columnId
    const productId = event.currentTarget.dataset.productId

    fetch('/tickets', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Accept': 'text/vnd.turbo-stream.html'
      },
      body: JSON.stringify({
        ticket: {
          title: "New Ticket",
          status: status.toLowerCase().replace(/\s+/g, '_'),
          product_id: productId
        }
      })
    })
    .then(response => {
      if (response.ok) {
        return response.text()
      } else {
        return response.json().then(data => {
          console.error("Error creating ticket:", data.errors)
          throw new Error("Network response was not ok")
        })
      }
    })
    .then(turboStream => {
      const columnTickets = document.getElementById(`column_${columnId}_tickets`)
      columnTickets.insertAdjacentHTML('beforeend', turboStream)
    })
    .catch(error => console.error("Error:", error))
  }

  updateFilter(event) {
    if (event.currentTarget.dataset.filterValue === "product_id") {
      this.productIdValue = event.currentTarget.dataset.value
    } else if (event.currentTarget.dataset.filterValue === "tag_name") {
      this.tagNameValue = event.currentTarget.dataset.value
    } else if (event.currentTarget.dataset.filterValue === "assignee_id") {
      this.assigneeIdValue = event.currentTarget.dataset.value
    } else if (event.currentTarget.dataset.filterValue === "status") {
      this.statusValue = event.currentTarget.dataset.value
    } else if (event.currentTarget.dataset.filterValue === "reporter_id") {
      console.log("Reported value:", event.currentTarget.dataset.value)
      this.reporterIdValue = event.currentTarget.dataset.value
    } else if (event.currentTarget.dataset.filterValue === "ticketable_id") {
      this.ticketableIdValue = event.currentTarget.dataset.value
    }
    const url = new URL(window.location.href)
    
    if (this.productIdValue) {
      url.searchParams.set("product_id", this.productIdValue)
    } else {
      url.searchParams.delete("product_id")
    }
    if (this.tagNameValue) {
      url.searchParams.set("tag_name", this.tagNameValue)
    } else {
      url.searchParams.delete("tag_name")
    }
    if (this.assigneeIdValue) {
      url.searchParams.set("assignee_id", this.assigneeIdValue)
    } else {
      url.searchParams.delete("assignee_id")
    }
    if (this.statusValue) {
      url.searchParams.set("status", this.statusValue)
    } else {
      url.searchParams.delete("status")
    }
    if (this.reporterIdValue) {
      url.searchParams.set("reporter_id", this.reporterIdValue)
    } else {
      url.searchParams.delete("reporter_id")
    }
    if (this.ticketableIdValue) {
      url.searchParams.set("ticketable_id", this.ticketableIdValue)
      url.searchParams.set("ticketable_type", "Feature") // Change this if we add more ticketable types
    } else {
      url.searchParams.delete("ticketable_id")
      url.searchParams.delete("ticketable_type")
    }
    window.history.pushState({}, '', url.toString())

    fetch(url.toString(), {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
    })
    .catch(error => console.error("Error:", error))
  }
}