/* eslint no-console:0 */

// Rails functionality
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import "flowbite/dist/flowbite.turbo.js";
import "chartkick/chart.js"
import "./excalidraw.jsx";

// Make accessible for Electron and Mobile adapters
window.Rails = Rails

require("@rails/activestorage").start()

// ActionCable Channels
import "./channels"

// Stimulus controllers
import "./controllers"

// Jumpstart Pro & other Functionality
import "./src/**/*"
require("local-time").start()

// Start Rails UJS
Rails.start()

import Alpine from "alpinejs"
window.Alpine = Alpine  
