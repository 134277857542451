// app/javascript/services/generatedHtmlService.ts

import axiosInstance from '../config/axiosConfig';
import { AnthropicResponse } from '../components/Excalidraw/types';
import { PromptMessages } from '../components/Excalidraw/types/frameTypes';

interface PersistedFrame {
  id: number;
  imageUrl: string;
  elements?: any;
  frame_htmls: string[];
}

export const generatedHtmlService = {
  generateHtml: async (input: string): Promise<AnthropicResponse> => {
    try {
      console.log("generateHtml:", input);
      const response = await axiosInstance.post('/generate_diagram_to_html', { input });
      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.data;
    } catch (error) {
      console.error("Error generating HTML:", error);
      throw error;
    }
  },

  generateDiagramToHtml: async (
    frame: PersistedFrame,
    featureDiagramId: string,
    featureId: string,
    promptMessages: PromptMessages
  ): Promise<AnthropicResponse> => {
    try {
      const formData = new FormData();
      formData.append('feature_diagram_id', featureDiagramId.toString());
      formData.append('frame_id', frame.id.toString());
      formData.append('feature_id', featureId);
      formData.append('prompt_messages', JSON.stringify(promptMessages));

      if (frame.elements) {
        formData.append('frame_elements', JSON.stringify(frame.elements));
      }

      const response = await axiosInstance.post("/generate_diagram_to_html", formData);

      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return response.data;
    } catch (error) {
      console.error("Error generating HTML from diagram:", error);
      throw error;
    }
  },
};