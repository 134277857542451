import React, { useState, useCallback, useEffect } from 'react';
import { Typography, Box, Paper, Chip, Divider, useTheme, TextField, List, ListItem, ListItemIcon, ListItemText, Tabs, Tab } from '@mui/material';
import { PersistedFrame } from '../../types/frameTypes';
import FrameAssociationTab from './FrameAssociationTab';
import FrameMappingTab from './FrameMappingTab';
import useFrameToHTML from '../../hooks/useFrameToHTML';

import InfoIcon from '@mui/icons-material/Info';
import DescriptionIcon from '@mui/icons-material/Description';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import GeneratingHTMLTab from './GeneratingHTMLTab';
import { ExcalidrawImperativeAPI } from '@excalidraw/excalidraw/types/types';
import { useSelector } from 'react-redux';
import { 
  selectSelectedFrame, 
} from '../../stores/selectors/framesSelector';

interface SelectedContentProps {
  selectedFrame: PersistedFrame | null;
  updateFrame: (frameId: number, updateData: Partial<PersistedFrame>) => Promise<boolean>;
  excalidrawAPI: ExcalidrawImperativeAPI
  featureId: string;
  featureDiagramId: string;
}

const SelectedContent: React.FC<SelectedContentProps> = ({ updateFrame, excalidrawAPI, featureId, featureDiagramId }) => {
  const theme = useTheme();
  const [editingTitle, setEditingTitle] = useState(false);
  const [editingDescription, setEditingDescription] = useState(false);
  const [tempTitle, setTempTitle] = useState('');
  const [tempDescription, setTempDescription] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const selectedFrame = useSelector(selectSelectedFrame);

  const {
    generateHtml,
    isGeneratingHtml,
    generatedHtmlUrl,
    error
  } = useFrameToHTML({ excalidrawAPI, featureId });

  useEffect(() => {
    if (selectedFrame) {
      setTempTitle(selectedFrame.name);
      setTempDescription(selectedFrame.description || '');
    }
  }, [selectedFrame]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleDoubleClickTitle = () => {
    if (selectedFrame) {
      setEditingTitle(true);
    }
  };

  const handleDoubleClickDescription = () => {
    if (selectedFrame) {
      setEditingDescription(true);
    }
  };

  const handleTitleKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && selectedFrame) {
      const success = await updateFrame(selectedFrame.id, { name: tempTitle });
      if (success) {
        setEditingTitle(false);
      }
    }
  };

  const handleDescriptionKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && selectedFrame) {
      const success = await updateFrame(selectedFrame.id, { description: tempDescription });
      if (success) {
        setEditingDescription(false);
      }
    }
  };

  const handleTitleBlur = useCallback(async () => {
    if (selectedFrame && tempTitle !== selectedFrame.name) {
      await updateFrame(selectedFrame.id, { name: tempTitle });
    }
    setEditingTitle(false);
  }, [selectedFrame, tempTitle, updateFrame]);

  const handleGenerateHtml = async () => {
    const result = await generateHtml();
    if (result) {
      // Handle successful generation, e.g., show a success message or open the URL
      window.open(result.htmlUrl, '_blank');
    }
  };

  const handleDescriptionBlur = useCallback(async () => {
    if (selectedFrame && tempDescription !== selectedFrame.description) {
      await updateFrame(selectedFrame.id, { description: tempDescription });
    }
    setEditingDescription(false);
  }, [selectedFrame, tempDescription, updateFrame]);

  if (!selectedFrame) return null;

  return (
    <Paper
      elevation={3}
      sx={{
        mt: 3,
        p: 3,
        borderTop: `4px solid ${theme.palette.primary.main}`,
        borderRadius: '8px',
        background: `linear-gradient(145deg, ${theme.palette.background.paper} 0%, ${theme.palette.grey[100]} 100%)`,
      }}
    >
      <Box display="flex" alignItems="center" mb={2}>
        <InfoIcon sx={{ color: theme.palette.primary.main, mr: 1, fontSize: 28 }} />
        {editingTitle ? (
          <TextField
            value={tempTitle}
            onChange={(e) => setTempTitle(e.target.value)}
            onKeyPress={handleTitleKeyPress}
            onBlur={handleTitleBlur}
            autoFocus
            fullWidth
            variant="standard"
          />
        ) : (
          <Typography
            variant="h5"
            color="primary"
            fontWeight="bold"
            onDoubleClick={handleDoubleClickTitle}
          >
            {selectedFrame.name}
          </Typography>
        )}
      </Box>
      <Divider sx={{ mb: 2 }} />

      <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
        <Tab label="Main" />
        <Tab label="Generate HTML" />
        <Tab label="Frame Association" />
        <Tab label="Frame Mapping" />
      </Tabs>

      {activeTab === 0 && (
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" alignItems="flex-start">
            <DescriptionIcon sx={{ color: theme.palette.secondary.main, mr: 1, mt: 0.5 }} />
            <Box flexGrow={1}>
              <Typography variant="subtitle1" fontWeight="bold" color="secondary">
                Description
              </Typography>
              {editingDescription ? (
                <TextField
                  value={tempDescription}
                  onChange={(e) => setTempDescription(e.target.value)}
                  onKeyPress={handleDescriptionKeyPress}
                  onBlur={handleDescriptionBlur}
                  autoFocus
                  fullWidth
                  multiline
                  variant="standard"
                />
              ) : (
                <Typography variant="body1" onDoubleClick={handleDoubleClickDescription}>
                  {selectedFrame.description || 'No description available'}
                </Typography>
              )}
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <FormatListNumberedIcon sx={{ color: theme.palette.info.main, mr: 1 }} />
            <Typography variant="subtitle1" fontWeight="bold" color="info.main">
              Position:
            </Typography>
            <Chip
              label={selectedFrame.position}
              color="info"
              size="small"
              sx={{ ml: 1, fontWeight: 'bold' }}
            />
          </Box>
          <Box mt={2} p={2} bgcolor={theme.palette.grey[100]} borderRadius={1}>
            <Typography variant="body2" color="text.secondary" fontStyle="italic">
              This frame contains important information related to your project.
              You can edit its details, reposition it, or use it as a reference for your work.
            </Typography>
          </Box>
        </Box>
      )}

      {activeTab === 1 && (
        <GeneratingHTMLTab
          onGenerateHtml={handleGenerateHtml}
          selectedFrame={selectedFrame}
          isGenerating={isGeneratingHtml}
          featureDiagramId={featureDiagramId}
          generatedUrl={generatedHtmlUrl}
          error={error}
        />
      )}
      {activeTab === 2 && <FrameAssociationTab />}
      {activeTab === 3 && <FrameMappingTab />}
    </Paper>
  );
};

export default SelectedContent;