// app/javascript/components/Excalidraw/stores/slices/framesSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { PersistedFrame, FrameFormData } from '../../types/frameTypes';
import { framesService } from '../../../../services/framesService';

interface FramesState {
  frames: PersistedFrame[];
  selectedFrameId: number | null;
  isManageFramesModalOpen: boolean;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: FramesState = {
  frames: [],
  selectedFrameId: null,
  isManageFramesModalOpen: false,
  loading: 'idle',
  error: null,
};

export const fetchFrames = createAsyncThunk(
  'frames/fetchFrames',
  async (featureDiagramId: string) => {
    return await framesService.fetchFrames(featureDiagramId);
  }
);

export const createFrame = createAsyncThunk(
  'frames/createFrame',
  async ({ featureDiagramId, frameData }: { featureDiagramId: string, frameData: FrameFormData }) => {
    const response = await framesService.createFrame(featureDiagramId, frameData);
    // Ensure the preview image URL is set correctly
    return {
      ...response,
      imageUrl: response.preview || response.imageUrl,
    };
  }
);

export const updateFrame = createAsyncThunk(
  'frames/updateFrame',
  async ({ featureDiagramId, frameId, updateData }: { featureDiagramId: string, frameId: number, updateData: Partial<PersistedFrame> }) => {
    const success = await framesService.updateFrame(featureDiagramId, frameId, updateData);
    if (success) {
      return { frameId, updateData };
    }
    throw new Error('Failed to update frame');
  }
);

export const deleteFrame = createAsyncThunk(
  'frames/deleteFrame',
  async ({ featureDiagramId, frameId }: { featureDiagramId: string, frameId: number }) => {
    await framesService.deleteFrame(featureDiagramId, frameId);
    return frameId;
  }
);

export const updateFramePositions = createAsyncThunk(
  'frames/updateFramePositions',
  async ({ featureDiagramId, frames }: { featureDiagramId: string, frames: { id: number, position: number }[] }) => {
    await framesService.updateFramePositions(featureDiagramId, frames);
    return frames;
  }
);

const framesSlice = createSlice({
  name: 'frames',
  initialState,
  reducers: {
    setSelectedFrameId: (state, action: PayloadAction<number | null>) => {
      state.selectedFrameId = action.payload;
    },
    setIsManageFramesModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isManageFramesModalOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFrames.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchFrames.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.frames = action.payload;
      })
      .addCase(fetchFrames.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message || null;
      })
      .addCase(createFrame.fulfilled, (state, action) => {
        console.log('create frame fulfilled: ', action.payload);
        state.frames.push({
          ...action.payload,
          isPersisted: true,
          elements: action.payload.elements || '[]',
          frame_htmls: action.payload.frame_htmls || [],
        });
      })
      .addCase(updateFrame.fulfilled, (state, action) => {
        const { frameId, updateData } = action.payload;
        const frameIndex = state.frames.findIndex(frame => frame.id === frameId);
        if (frameIndex !== -1) {
          state.frames[frameIndex] = { ...state.frames[frameIndex], ...updateData };
        }
      })
      .addCase(deleteFrame.fulfilled, (state, action) => {
        state.frames = state.frames.filter(frame => frame.id !== action.payload);
        if (state.selectedFrameId === action.payload) {
          state.selectedFrameId = null;
        }
      })
      .addCase(updateFramePositions.fulfilled, (state, action) => {
        action.payload.forEach(({ id, position }) => {
          const frame = state.frames.find(f => f.id === id);
          if (frame) {
            frame.position = position;
          }
        });
        state.frames.sort((a, b) => a.position - b.position);
      });
  },
});

export const { 
  setSelectedFrameId, 
  setIsManageFramesModalOpen, 
} = framesSlice.actions;

export const framesReducer = framesSlice.reducer;