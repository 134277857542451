import React from 'react';
import { Typography, Box, Divider, List, ListItem, ListItemIcon, ListItemText, Paper, useTheme } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const FrameMappingTab: React.FC = () => {
  const theme = useTheme();

  return (
    <Box mt={3}>
      <Typography variant="h6" color="primary" fontWeight="bold" sx={{ display: 'flex', alignItems: 'center' }}>
        <MapIcon sx={{ mr: 1 }} />
        Coming Soon: Frame Mapping
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ p: 2, bgcolor: theme.palette.grey[100], borderRadius: 1, mt: 2 }}>
        <Typography variant="body1" paragraph>
          Frame Mapping will allow you to create visual connections between different frames and their elements.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Paper sx={{ p: 1, width: '30%', textAlign: 'center' }}>Frame 1</Paper>
          <ArrowRightAltIcon />
          <Paper sx={{ p: 1, width: '30%', textAlign: 'center' }}>Frame 2</Paper>
          <ArrowRightAltIcon />
          <Paper sx={{ p: 1, width: '30%', textAlign: 'center' }}>Frame 3</Paper>
        </Box>
        <List dense>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="success" />
            </ListItemIcon>
            <ListItemText primary="Map elements across frames" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="success" />
            </ListItemIcon>
            <ListItemText primary="Visualize relationships" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="success" />
            </ListItemIcon>
            <ListItemText primary="Track element evolution" />
          </ListItem>
        </List>
      </Box>
      <Typography variant="body2" color="textSecondary" sx={{ mt: 2, fontStyle: 'italic' }}>
        Frame Mapping will provide a powerful way to understand and manage the relationships
        between different parts of your project across multiple frames.
      </Typography>
    </Box>
  );
};

export default FrameMappingTab;