import { useState, useCallback, useEffect, useRef } from 'react';
import { Excalidraw, convertToExcalidrawElements, Footer, sceneCoordsToViewportCoords } from "@excalidraw/excalidraw";
import { ExcalidrawImperativeAPI, BinaryFiles } from "@excalidraw/excalidraw/types/types";
import {parseMermaidToExcalidraw} from "@excalidraw/mermaid-to-excalidraw";
import { exportToBlob } from "@excalidraw/excalidraw";
import axiosInstance from '../../../config/axiosConfig';

interface ExcalidrawElement {
    id: string;
    type: string;
    x: number;
    y: number;
    updated: number;
}

interface SceneData {
    elements: ExcalidrawElement[];
    appState: any;
    files: BinaryFiles;
}

interface UseExcalidrawDataProps {
    featureId: string;
    diagramId: string;
}

export const useExcalidrawData = ({ featureId, diagramId }: UseExcalidrawDataProps) => {
    const [sceneData, setSceneData] = useState<SceneData>({
        elements: [],
        appState: {},
        files: {},
    });
    const [excalidrawKey, setExcalidrawKey] = useState<number>(Date.now());
    const [lastSavedData, setLastSavedData] = useState<string | null>(null);
    const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout | null>(null);
    const [currentElementCount, setCurrentElementCount] = useState<number>(0);
    const [isGeneratingDiagram, setIsGeneratingDiagram] = useState<boolean>(false);
    const [isGeneratingHtml, setIsGeneratingHtml] = useState<boolean>(false);
    const [excalidrawAPI, setExcalidrawAPI] = useState<ExcalidrawImperativeAPI | null>(null);
    const [slideOverOpen, setSlideOverOpen] = useState<boolean>(false);

    const SYNC_PERIOD = 5000; // Check every 5 seconds

    const viewportCoordsRef = useRef({ x: 0, y: 0 });

    const saveFeatureDiagram = useCallback(
        async (elements: ExcalidrawElement[], files: BinaryFiles) => {
            try {
                const response = await axiosInstance.post(
                    `/feature_diagrams/${diagramId}/create_and_update_element?diagram_id=${diagramId}`,
                    { elements, files }
                );
                if (response.status !== 200) {
                    throw new Error("Failed to save feature diagram");
                }
            } catch (error) {
                console.error("Error saving feature diagram:", error);
            }
        },
        [featureId]
    );

                
    const saveFile = useCallback(async (fileData: any, elementData: any) => {
        try {
            await new Promise((resolve) => setTimeout(resolve, 2000));
            const response = await axiosInstance.post(
                `/feature_diagrams/${diagramId}/create_and_update_file`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ fileData, elementData }),
                }
            );
        } catch (error) { console.error("Error saving file:", error) }
    }, []);

    const debouncedSaveFeatureDiagram = useCallback(
        (elements: ExcalidrawElement[], files: BinaryFiles) => {
            const currentData = JSON.stringify({ elements, files });

            if (currentData !== lastSavedData) {
                if (saveTimeout) {
                    clearTimeout(saveTimeout);
                }

                setSaveTimeout(
                    setTimeout(() => {
                        saveFeatureDiagram(elements, files);
                        setLastSavedData(currentData);
                    }, 3000)
                );
            }
        },
        [lastSavedData, saveFeatureDiagram]
    );

    const handleChange = useCallback(
        (elements: ExcalidrawElement[], appState: any, files: BinaryFiles) => {
            const currentTime = Date.now();
            Object.entries(files).forEach(([fileKey, fileData]) => {
                if (fileData.customData && fileData.customData.uploaded) {
                    return;
                }

                const timeDiff = currentTime - (fileData.created || 0);
                if (timeDiff <= SYNC_PERIOD) {
                    const elementData = elements.find((element) => element.fileId === fileKey);

                    setTimeout(() => {
                        saveFile(fileData, elementData);
                    }, 100);

                    fileData.customData = { uploaded: true, upload_status: "pending" };
                }
            });

            const { x: newX, y: newY } = sceneCoordsToViewportCoords(
                { sceneX: appState.scrollX, sceneY: appState.scrollY },
                appState
            );

            const roundedX = Math.round(newX);
            const roundedY = Math.round(newY);

            if (
                roundedX !== viewportCoordsRef.current.x ||
                roundedY !== viewportCoordsRef.current.y
            ) {
                viewportCoordsRef.current = { x: roundedX, y: roundedY };
            }

            if (
                elements.length !== currentElementCount ||
                JSON.stringify(elements) !== JSON.stringify(sceneData.elements)
            ) {
                setSceneData({ elements, appState, files });
                setCurrentElementCount(elements.length);
                debouncedSaveFeatureDiagram(elements, files);
            }
        },
        [currentElementCount, debouncedSaveFeatureDiagram, sceneData.elements]
    );

    useEffect(() => {
        const fetchSavedDiagram = async () => {
            try {
                const response = await fetch(`/feature_diagrams/${diagramId}/fetch_initial_data`);

                if (!response.ok) { throw new Error("Feature diagram not found") }
                const data = await response.json();

                // Function to process each file
                const processFile = (file: any) => {
                    return new Promise<any>((resolve, reject) => {
                        const reader = new FileReader();
                        const json_file = JSON.parse(file);

                        fetch(json_file.dataURL)
                            .then(res => res.blob())
                            .then(imageData => {
                                console.log("imageData", imageData);
                                reader.readAsDataURL(imageData);

                                reader.onload = function () {
                                    resolve({
                                        id: json_file.id,
                                        dataURL: reader.result,
                                        mimeType: json_file.mimeType,
                                        created: json_file.created,
                                        lastRetrieved: Date.now()
                                    });
                                };

                                reader.onerror = function (error) {
                                    reject(error);
                                };
                            })
                            .catch(error => reject(error));
                    });
                };

                // Process all files
                const processFiles = async (files: any[]) => {
                    const processedFiles = await Promise.all(files.map(processFile));

                    // Convert the array of processed files to the expected BinaryFiles format
                    const binaryFiles = processedFiles.reduce((acc: BinaryFiles, file: any) => {
                        acc[file.id] = {
                            mimeType: file.mimeType,
                            id: file.id,
                            dataURL: file.dataURL,
                            created: file.created,
                            lastRetrieved: file.lastRetrieved
                        };
                        return acc;
                    }, {});
                    return binaryFiles;
                };

                const loadedFiles = await processFiles(data.files);

                // Check if the data contains the expected structure
                if (data.elements && data.files) {
                    setSceneData((prevSceneData) => ({
                        ...prevSceneData,
                        elements: data.elements,
                        appState: { ...prevSceneData.appState },
                        files: loadedFiles,
                    }));

                    // Trigger a re-render of the Excalidraw component
                    setExcalidrawKey((prevKey) => prevKey + 1);
                    setCurrentElementCount(data.elements.length);
                } else { console.error("Invalid response format:", data) }
            } catch (error) { console.error("Error fetching saved diagram:", error) }
        };

        fetchSavedDiagram();
    }, []);

    const fetchDiagramToHtml = useCallback(async () => {
        setIsGeneratingHtml(true);
        try {
          if (!excalidrawAPI) {throw new Error("Excalidraw API is not available")}
      
          // Export the current diagram as a blob
          const blob = await exportToBlob({
            elements: excalidrawAPI.getSceneElements(),
            appState: excalidrawAPI.getAppState(),
            files: excalidrawAPI.getFiles(),
            mimeType: "image/png",
            quality: 1,
          });
      
          const formData = new FormData();
          formData.append('image', blob, 'diagram.png');
          formData.append('featureId', featureId);
      
          const endpoint = "/generate_diagram_to_html";
          const response = await axiosInstance.post(endpoint, formData);
          if (response.status !== 200) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
      
          const result = await response.data;
          if (response.status !== 200) { throw new Error(`HTTP error! status: ${response.status}`)}
      
          return result;
        } catch (error) {
          console.error("Error generating HTML:", error);
        } finally {
          setIsGeneratingHtml(false);
          
        }
      }, [excalidrawAPI, featureId]);

    const fetchFeatureDiagram = useCallback(
        async (useAI = false) => {
            try {
                setIsGeneratingDiagram(true);
                const endpoint = "/generate_feature_diagram";
                const response = await fetch(`${endpoint}?id=${featureId}`);
                if (!response.ok) {
                    throw new Error("Feature diagram not found");
                }
                const data = await response.json();
                if (data.diagram) {
                    const { elements: skeletonElements, files: parsedFiles } =
                        await parseMermaidToExcalidraw(data.diagram, {
                            fontSize: 16,
                        });
                    const excalidrawElements =
                        convertToExcalidrawElements(skeletonElements);

                    // Calculate the center coordinates of the screen
                    const screenWidth = window.innerWidth;
                    const screenHeight = window.innerHeight;
                    const centerX = screenWidth / 2;
                    const centerY = screenHeight / 2;

                    // Calculate the offset to move the elements to the center of the screen
                    const offsetX = centerX - viewportCoordsRef.current.x;
                    const offsetY = centerY - viewportCoordsRef.current.y;

                    // Update the coordinates of the generated elements
                    excalidrawElements.forEach((element) => {
                        element.x += offsetX;
                        element.y += offsetY;
                    });

                    await saveFeatureDiagram(excalidrawElements, parsedFiles);

                    setSceneData((prevSceneData) => ({
                        elements: [...prevSceneData.elements, ...excalidrawElements],
                        appState: { ...prevSceneData.appState, files: parsedFiles || {} },
                        files: prevSceneData.files,
                    }));
                } else if (data.elements && data.files) {
                    setSceneData((prevSceneData) => ({
                        ...prevSceneData,
                        elements: [...prevSceneData.elements, ...data.elements],
                        appState: { ...prevSceneData.appState, files: data.files || {} },
                    }));
                } else { console.error('Invalid response format:', data) }
                setExcalidrawKey((prevKey) => prevKey + 1);
            } catch (error) {
                console.error('Error fetching feature diagram:', error);
            } finally {
                setIsGeneratingDiagram(false);
            }
        },
        [featureId, viewportCoordsRef, saveFeatureDiagram]
    );

    useEffect(() => {
        const checkElementChanges = () => {
            const currentTime = Date.now();
            const elements = excalidrawAPI?.getSceneElements() || [];

            const changedElements = elements.filter((element) => {
                const timeDiff = currentTime - (element.updated || 0);
                return timeDiff <= SYNC_PERIOD; // Find elements changed within the last 5 seconds
            });

            if (changedElements.length > 0) {
                saveFeatureDiagram(changedElements, sceneData.files);
            }
        };

        const intervalId = setInterval(checkElementChanges, SYNC_PERIOD);

        return () => {
            clearInterval(intervalId);
        };
    }, [excalidrawAPI]);

    const exportToImage = useCallback(async () => {
        if (!excalidrawAPI) {
          console.error("Excalidraw API is not available");
          return;
        }
      
        try {
          const blob = await exportToBlob({
            elements: excalidrawAPI.getSceneElements(),
            appState: excalidrawAPI.getAppState(),
            files: excalidrawAPI.getFiles(),
            mimeType: "image/png",
            quality: 1,
            exportPadding: 10,
          });
      
          // Create a download link
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'excalidraw-export.png';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error("Error exporting image:", error);
        }
      }, [excalidrawAPI]);

    return {
        sceneData,
        excalidrawKey,
        isGeneratingDiagram,
        setExcalidrawAPI,
        handleChange,
        setExcalidrawKey,
        setIsGeneratingDiagram,
        viewportCoordsRef,
        fetchFeatureDiagram,
        isGeneratingHtml,
        fetchDiagramToHtml,
        exportToImage,
        slideOverOpen,
        excalidrawAPI,
        setSlideOverOpen,
        setIsGeneratingHtml,
    };
};