// app/javascript/components/Excalidraw/components/SlideOver/FramesTab/SnapshottedFrame.tsx

import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { SnapshottedFrame as SnapshottedFrameType } from '../../../types/frameTypes';


interface SnapshottedFrameProps {
  frame: SnapshottedFrameType;
}

const PreviewContainer = styled(Paper)(({ theme }) => ({
  width: '100%',
  height: '12rem',
  backgroundColor: '#424242',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const PreviewImage = styled('img')({
  maxWidth: '100%',
  maxHeight: '12rem',
  objectFit: 'contain',
});

const SnapshottedFrame: React.FC<SnapshottedFrameProps> = ({ frame }) => {
  return (
    <Box mt={4}>
      <PreviewContainer elevation={3}>
        <PreviewImage
          src={frame.imageUrl}
          alt="New Frame Preview"
        />
      </PreviewContainer>
    </Box>
  );
};

export default React.memo(SnapshottedFrame);