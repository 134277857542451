// app/javascript/components/Excalidraw/components/SlideOver/GeneratingHTMLTab.tsx

import React, { useCallback, useState } from 'react';
import { Box, Button, Paper, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { AnthropicResponse } from '../../types';
import useGenerateHTML from '../../hooks/useGenerateHTML';
import GeneratedHtml from '../GeneratedHtml';
import { ExcalidrawImperativeAPI } from '@excalidraw/excalidraw/types/types';
import useFrames from '../../hooks/useFrames';
import { PersistedFrame } from '../../types/frameTypes';

interface GenerateHTMLTabProps {
  excalidrawAPI: ExcalidrawImperativeAPI | null;
  selectedFrame: PersistedFrame;
  featureDiagramId: string;
  featureId: string;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.info.light,
  color: theme.palette.info.contrastText,
}));

const GenerateHTMLTab: React.FC<GenerateHTMLTabProps> = ({ 
  excalidrawAPI, 
  selectedFrame,
  featureDiagramId, 
  featureId 
}) => {
  const [showAiResponse, setShowAiResponse] = useState(false);
  const [currentHtml, setCurrentHtml] = useState(selectedFrame.frame_htmls[0]);
  const [isGeneratingHtml, setIsGeneratingHtml] = useState(false);

  const { fetchDiagramToHtml } = useGenerateHTML(excalidrawAPI, featureDiagramId, featureId);
  const { handlePromptSubmit } = useFrames(excalidrawAPI, featureDiagramId, featureId);

  const handleGenerateHTML = useCallback(async () => {
    if (selectedFrame) {
      try {
        setIsGeneratingHtml(true);
        const result = await fetchDiagramToHtml(selectedFrame);
        setShowAiResponse(true);

        if (result && result.html && result.html.content && result.html.content[0]) {
          setCurrentHtml(result.html.content[0].text);
        }
      } catch (error) {
        console.error("Error generating HTML:", error);
      } finally {
        setIsGeneratingHtml(false);
      }
    }
  }, [selectedFrame, fetchDiagramToHtml]);

  const onPromptSubmit = useCallback(async (prompt: { type: string; text: string }) => {
    if (selectedFrame) {
      try {
        setIsGeneratingHtml(true);
        const result = await handlePromptSubmit(prompt, selectedFrame.id, selectedFrame);
        setShowAiResponse(true);

        if (result && result.html && result.html.content && result.html.content[0]) {
          setCurrentHtml(result.html.content[0].text);
        }
      } catch (error) {
        console.error("Error submitting prompt:", error);
      } finally {
        setIsGeneratingHtml(false);
      }
    }
  }, [selectedFrame, handlePromptSubmit]);

  return (
    <Box>
      <StyledPaper>
        <Box display="flex" alignItems="center">
          <InfoIcon sx={{ mr: 1 }} />
          <Typography variant="body2">
            Generate HTML from your Excalidraw diagram using Tailwind CSS and React.
          </Typography>
        </Box>
      </StyledPaper>
      <GeneratedHtml 
        html={currentHtml} 
        onPromptSubmit={onPromptSubmit}
        isGeneratingHtml={isGeneratingHtml}
        setIsGeneratingHtml={setIsGeneratingHtml}
      />
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateHTML}
          disabled={isGeneratingHtml}
          startIcon={isGeneratingHtml ? <CircularProgress size={20} color="inherit" /> : null}
        >
          {isGeneratingHtml ? 'Generating...' : 'Diagram to HTML'}
        </Button>
      </Box>
    </Box>
  );
};

export default React.memo(GenerateHTMLTab);