import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];
  static values = { timeout: Number };

  animate(event) {
    const button = this.buttonTarget;
    const form = document.getElementById('user_form')

    if (form) {
      form.submit();
    }
    event.preventDefault();

    button.classList.remove("animate");
    button.classList.add("animate");

    setTimeout(() => {
      this.buttonTarget.classList.remove("animate");
      const nextUrl = this.buttonTarget.dataset.nextUrl;



      if (nextUrl) {
        window.location.href = nextUrl;
      }
    }, 700);
  }
}
