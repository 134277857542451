import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {

  connect() {
    // Initialize Sortable for feature groups
    this.sortableGroups = Sortable.create(this.element, {
      group: {
        name: "feature-groups",
        sortable: true,
        pull: true, // Allow reordering within the same container
        put: false // Prevent feature groups from being dropped into other feature groups
      },
      animation: 150,
      handle: ".group-handle", // Ensure only elements with the group-handle class can be dragged
      onEnd: this.endGroup.bind(this)
    })

    // Initialize Sortable for individual features within each group
    this.element.querySelectorAll("[data-group-id]").forEach(groupElement => {
      Sortable.create(groupElement, {
        group: {
          name: "features",
          pull: true,
          put: true
        },
        animation: 150,
        handle: ".handle",
        onEnd: this.endFeature.bind(this)
      })
    })

    // Initialize Sortable for ungrouped features
    this.sortableUngrouped = Sortable.create(this.element.querySelector("#ungrouped-features"), {
      group: {
        name: "features",
        pull: true,
        put: true
      },
      animation: 150,
      handle: ".handle",
      onEnd: this.endFeature.bind(this)
    })
  }

  endGroup(event) {
    const id = event.item.dataset.id
    const url = event.to.dataset.dragUrl.replace(":id", id)
    const position = event.newIndex

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
      },
      body: JSON.stringify({ position: position })
    })
  }

  endFeature(event) {
    const id = event.item.dataset.id
    const newGroupId = event.to.dataset.groupId
    const url = event.to.dataset.dragUrl.replace(":id", id)
    const position = event.newIndex

    // Get the current position and nearby positions
    const currentPosition = parseInt(event.item.dataset.position, 10)
    const previousPosition = position > 0 ? parseInt(event.to.children[position - 1].dataset.position, 10) : 0
    const nextPosition = position < event.to.children.length - 1 ? parseInt(event.to.children[position + 1].dataset.position, 10) : currentPosition + 1

    // Calculate the new position
    const newPosition = previousPosition + (nextPosition - previousPosition) / 2

    const body = newGroupId ? { feature_group_id: newGroupId, position: newPosition } : { position: newPosition }

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
      },
      body: JSON.stringify(body)
    })
  }
}
