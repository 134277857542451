// app/javascript/components/Excalidraw/hooks/useGenerateHTML.ts

import { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnthropicResponse } from '../types';
import DOMPurify from 'dompurify';
import { ExcalidrawImperativeAPI } from "@excalidraw/excalidraw/types/types";
import { framesService } from '../../../services/framesService';
import { generatedHtmlService } from '../../../services/generatedHtmlService';
import { PromptMessages } from '../types/frameTypes';
import { updateFrame } from '../stores/slices/framesSlice';

interface PersistedFrame {
  id: number;
  imageUrl: string;
  elements?: any;
  frame_htmls: string[];
}

const useGenerateHTML = (excalidrawAPI: ExcalidrawImperativeAPI | null, featureDiagramId: string, featureId: string) => {
  console.log("Params: DiagramID: ", featureDiagramId, "FeatureID: ", featureId);
  const defaultResponseText = '// Generated HTML will appear here';
  const [inputText, setInputText] = useState('');
  const [responseText, setResponseText] = useState(defaultResponseText);
  const [isGeneratingHtml, setIsGeneratingHtml] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputText(e.target.value);
  }, []);

  const handleSubmitGeneratedHtml = useCallback(async () => {
    if (isGeneratingHtml) return;

    try {
      setIsGeneratingHtml(true);
      const result = await generatedHtmlService.generateHtml(inputText);

      let generatedHtml = '';
      if (result.success && result.html?.content?.[0]?.text) {
        generatedHtml = result.html.content[0].text;
        setResponseText(generatedHtml);
        
        const updatedFrameHtmls = [generatedHtml, ...(frame.frame_htmls || [])];
        const updatedFrame = { ...frame, frame_htmls: updatedFrameHtmls };
        dispatch(updateFrame({ featureDiagramId, frameId: frame.id, updateData: updatedFrame }));
      } else {
        setResponseText("Failed to generate HTML. Please try again.");
      }
    } catch (error) {
      console.error("Error generating HTML:", error);
      setResponseText("An error occurred while generating HTML. Please try again.");
    } finally {
      setIsGeneratingHtml(false);
    }
  }, [inputText, isGeneratingHtml]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(responseText);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  }, [responseText]);

  const fetchDiagramToHtml = useCallback(async (frame: PersistedFrame, promptMessages: PromptMessages) => {
    if (isGeneratingHtml) return;

    setIsGeneratingHtml(true);
    try {
      const result = await generatedHtmlService.generateDiagramToHtml(frame, featureDiagramId, featureId, promptMessages);
      
      console.log("frame.frame_htmls: ", frame.frame_htmls);
      let generatedHtml = '';
      if (result.success && result.html?.content?.[0]?.text) {
        generatedHtml = result.html.content[0].text;
        setResponseText(generatedHtml);
        
        const updatedFrameHtmls = [generatedHtml, ...(frame.frame_htmls || [])];
        const updatedFrame = { ...frame, frame_htmls: updatedFrameHtmls };
        dispatch(updateFrame({ featureDiagramId, frameId: frame.id, updateData: updatedFrame }));
      } else {
        generatedHtml = "HTML generation successful, but no content returned.";
        setResponseText(generatedHtml);
      }

      await framesService.saveFrameHtml(frame.id, generatedHtml);

      return result;
    } catch (error) {
      console.error("Error generating HTML:", error);
      setResponseText("An error occurred while generating HTML from the diagram. Please try again.");
    } finally {
      setIsGeneratingHtml(false);
    }
  }, [featureDiagramId, featureId, isGeneratingHtml]);

  const sanitizedHtml = useMemo(() => DOMPurify.sanitize(responseText), [responseText]);

  return {
    inputText,
    responseText,
    sanitizedHtml,
    isGeneratingHtml,
    setIsGeneratingHtml,
    isCopied,
    setInputText,
    handleInputChange,
    handleSubmitGeneratedHtml,
    handleCopy,
    fetchDiagramToHtml,
  };
};

export default useGenerateHTML;