import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["name", "description", "prompt", "submitButton"];
  static values = { productId: Number }

  connect() {
    console.log("connected");
    this.checkInputLength();
    this.setupAutoSave();
  }

  setupAutoSave() {
    this.autoSave = debounce(this.saveChanges.bind(this), 1500);
  }

  checkInputLength() {
    const nameLength = this.nameTarget.value.trim().length;
    const descriptionLength = this.descriptionTarget.value.trim().length;

    if (nameLength > 0 && descriptionLength > 0) {
      this.submitButtonTarget.classList.remove("disabled");
    } else {
      this.submitButtonTarget.classList.add("disabled");
    }
  }

  inputChanged() {
    console.log("Input changed");
    this.checkInputLength();
    this.autoSave();
  }

  async saveChanges() {
    const url = `/products/${this.productIdValue}`;
    const data = {
      product: {
        name: this.nameTarget.value,
        description: this.descriptionTarget.value
      }
    };

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
        }
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      console.log("Changes saved successfully");
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  }
}