import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    console.log("Highlight controller connected")
    this.containerTarget.addEventListener("mouseenter", this.handleMouseEnter.bind(this), true); // Using capture phase for better delegation
    this.containerTarget.addEventListener("mouseleave", this.handleMouseLeave.bind(this), true);
  }

  handleMouseEnter(event) {
    const button = event.target.closest('button.hover-effect');
    if (!button) return; // Do nothing if the event target is not a button

    const targetType = button.getAttribute('data-highlight-target-type');
    const targetName = button.getAttribute('data-highlight-param');

    // Reset highlights before applying new ones
    this.reset();

    switch (targetType) {
      case 'feature':
        this.highlightFeature(targetName);
        break;
      case 'role':
        this.highlightRole(targetName);
        break;
      case 'requirement':
        this.highlightRequirement(targetName);
        break;
    }
  }

  handleMouseLeave(event) {
    // Optionally, you can decide not to reset on mouse leave if you want the highlight to stay until another button is hovered over
    // this.reset();
  }

  highlightFeature(featureName) {
    // Your logic to find and highlight feature and its children goes here
    // For demonstration, let's assume we use a simple querySelector. Adjust accordingly.
    const featureElements = this.containerTarget.querySelectorAll(`[data-feature-name="${featureName}"]`);
    featureElements.forEach(el => this.highlightElement(el));
  }

  highlightRole(roleName) {
    const roleElements = this.containerTarget.querySelectorAll(`[data-role-name="${roleName}"]`);
    roleElements.forEach(el => this.highlightElement(el));
  }

  highlightRequirement(reqName) {
    const requirementElements = this.containerTarget.querySelectorAll(`[data-requirement-name="${reqName}"]`);
    requirementElements.forEach(el => this.highlightElement(el));
  }

  reset() {
    // Remove bg-green-200 from all elements that might have it
    this.containerTarget.querySelectorAll('.bg-green-200').forEach(el => {
      el.classList.remove("bg-green-200");
    });
  }

  highlightElement(element) {
    element.classList.add("bg-green-200"); // Highlight the element by adding the bg-green-200 class
  }
}
