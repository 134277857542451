import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

// Connects to data-controller="requirements-select-all"
export default class extends Controller {
  static targets = ["parent", "child", "link", "actionList"]
  static values = {
    showOutOfScope: { type: Boolean, default: true }
  }

  connect() { }

  selectAllChildren() {
    this.selectLinks(this.linkTargets)
  }

  selectAllInAction(event) {
    const actionType = event.currentTarget.dataset.actionType
    const actionList = this.actionListTargets.find(list => list.dataset.actionType === actionType)

    if (!actionList) {
      console.error(`No action list found for action type: ${actionType}`)
      return
    }

    const actionLinks = actionList.querySelectorAll('a[data-requirements-select-all-target="link"]')
    this.selectLinks(actionLinks)
  }

  selectLinks(links) {
    links.forEach(link => {
      if (link.href.includes("create_requirement_from_suggestion")) {
        post(link.href, {
          responseKind: "turbo-stream"
        })
      }
    })
  }

  toggleHideUnchecked() {
    const parentButton = this.parentTargets.find(parent => parent.id === 'hide-out-of-scope')

    if (this.showOutOfScopeValue) {
      this.showOutOfScopeValue = false
      parentButton.innerText = 'Show out of scope'
    } else {
      this.showOutOfScopeValue = true
      parentButton.innerText = 'Hide out of scope'
    }

    this.linkTargets.forEach(x => {
      const checkboxElement = x.querySelector('input[type="checkbox"]')

      if (checkboxElement && checkboxElement.checked) return
      x.classList.remove('hidden')
      if (this.showOutOfScopeValue) {
      } else {
        x.classList.add('hidden')
      }
    })
  }

  toggleParent() {
    if (this.childTargets.mapt(x => x.checked).includes(false)) {
      this.parentTarget.checked = true
    } else {
      this.parentTarget.checked = false
    }
  }

  // change_major(event) {
  //   let major = event.target.selectedOptions[0].value
  //   let target = this.majorSelectTarget.id
  //   // console.log(major)
  //   get(`${window.location.protocol}//${window.location.host}/submajor_from_major?target=${target}&major=${major}`, {
  //     responseKind: "turbo-stream"
  //   })
  // }
}
