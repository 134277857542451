// app/javascript/components/Excalidraw/hooks/useFrames.ts

import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { exportToBlob } from '@excalidraw/excalidraw';
import debounce from 'lodash/debounce';
import { FrameFormData, Frame, PersistedFrame, UnpersistedFrame } from '../types/frameTypes';
import { INITIAL_FRAME_FORM } from '../constants/frameConstants';
import { NonDeletedExcalidrawElement } from '@excalidraw/excalidraw/types/element/types';
import { ExcalidrawImperativeAPI } from '@excalidraw/excalidraw/types/types';
import useGenerateHTML from './useGenerateHTML';
import { PromptMessages, Prompt } from '../types/frameTypes';
import { 
  fetchFrames, 
  createFrame, 
  updateFrame, 
  deleteFrame, 
  updateFramePositions,
  setSelectedFrameId
} from '../stores/slices/framesSlice';
import { selectFrames, selectFramesLoading, selectFramesError } from '../stores/selectors/framesSelector';

const useFrames = (excalidrawAPI: ExcalidrawImperativeAPI, featureDiagramId: string, featureId: string) => {
  const dispatch = useDispatch();
  const frames = useSelector(selectFrames);
  const isLoading = useSelector(selectFramesLoading);
  const error = useSelector(selectFramesError);

  const [selectedElements, setSelectedElements] = useState<NonDeletedExcalidrawElement[]>([]);
  const [showWarning, setShowWarning] = useState(false);
  const [snapshottedFrame, setSnapshottedFrame] = useState<UnpersistedFrame | null>(null);
  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null);
  const [showFrameForm, setShowFrameForm] = useState(false);
  const [showAiResponse, setShowAiResponse] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [promptMessages, setPromptMessages] = useState<PromptMessages>({ messages: [] });
  const [currentHtml, setCurrentHtml] = useState<string>('');
  const [frameForm, setFrameForm] = useState<FrameFormData>({
    ...INITIAL_FRAME_FORM,
    name: "New Frame"
  });

  const { fetchDiagramToHtml } = useGenerateHTML(excalidrawAPI, featureDiagramId, featureId);

  const selectedElementsRef = useRef(selectedElements);

  useEffect(() => {
    selectedElementsRef.current = selectedElements;
  }, [selectedElements]);

  useEffect(() => {
    dispatch(fetchFrames(featureDiagramId));
  }, [dispatch, featureDiagramId]);

  const generatePreview = useCallback(async (elements: NonDeletedExcalidrawElement[]) => {
    if (excalidrawAPI && elements.length > 0) {
      try {
        const blob = await exportToBlob({
          elements: elements,
          appState: excalidrawAPI.getAppState(),
          files: excalidrawAPI.getFiles(),
          mimeType: "image/png",
          quality: 1,
          exportPadding: 10,
        });
        const imageUrl = URL.createObjectURL(blob);
        setPreviewImageUrl(imageUrl);
      } catch (error) {
        console.error('Error generating preview:', error);
      }
    } else {
      setPreviewImageUrl(null);
    }
  }, [excalidrawAPI]);

  const debouncedGeneratePreview = useMemo(
    () => debounce(generatePreview, 500),
    [generatePreview]
  );

  useEffect(() => {
    const updateSelectedElements = () => {
      if (excalidrawAPI) {
        const appState = excalidrawAPI.getAppState();
        const allElements = excalidrawAPI.getSceneElements();
        const selectedElementIds = Object.keys(appState.selectedElementIds);
        const selected = allElements.filter(element => selectedElementIds.includes(element.id));
        
        if (JSON.stringify(selected) !== JSON.stringify(selectedElementsRef.current)) {
          setSelectedElements(selected);
          debouncedGeneratePreview(selected);
        }
      }
    };

    const intervalId = setInterval(updateSelectedElements, 500);
    return () => clearInterval(intervalId);
  }, [excalidrawAPI, debouncedGeneratePreview]);

  const handleFrameFormChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFrameForm(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleDeleteFrame = useCallback(async (frameId: number) => {
    try {
      await dispatch(deleteFrame({ featureDiagramId, frameId })).unwrap();
    } catch (error) {
      console.error('Error deleting frame:', error);
    }
  }, [dispatch, featureDiagramId]);

  const handlePromptSubmit = useCallback(async (prompt: Prompt, frameId: number, arg_frame: PersistedFrame) => {
    const formattedPrompt = { type: "text", text: `${currentHtml}\n${prompt.text}` };
    const messagesToSend: PromptMessages = { messages: [...promptMessages.messages, formattedPrompt] };

    const selectedFrame = arg_frame || frames.find(frame => frame.id === frameId);
    if (selectedFrame) {
      try {
        const result = await fetchDiagramToHtml(selectedFrame, messagesToSend);
        if (result && result.html && result.html.content && result.html.content[0]) {
          const aiResponse = { type: "text", text: result.html.content[0].text };
          setPromptMessages({ messages: [...promptMessages.messages, aiResponse] });
          setCurrentHtml(result.html.content[0].text);

          return result.html.content[0].text;
        }
        setShowAiResponse(true);
      } catch (error) {
        console.error("Error generating HTML:", error);
      }
    }
  }, [frames, fetchDiagramToHtml, setShowAiResponse, promptMessages, currentHtml]);

  const handleFrameFormSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    if (!snapshottedFrame) return;
  
    setIsSubmitting(true);
    setSubmitError(null);
  
    try {
      const response = await fetch(snapshottedFrame.imageUrl);
      const blob = await response.blob();
  
      const reader = new FileReader();
      const base64ImagePromise = new Promise<string>((resolve) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
      });
      const base64Image = await base64ImagePromise;
  
      const highestPosition = Math.max(...frames.map(frame => frame.position), 0);
      const newPosition = highestPosition + 1;
  
      const frameData = {
        ...frameForm,
        feature_diagram_id: featureDiagramId,
        preview: base64Image,
        position: newPosition,
        elements: JSON.stringify(snapshottedFrame.elements),
        frame_htmls: [],
      };
  
      await dispatch(createFrame({ featureDiagramId, frameData })).unwrap();
  
      setShowFrameForm(false);
      setFrameForm(INITIAL_FRAME_FORM);
      setSnapshottedFrame(null);
    } catch (error) {
      console.error('Error creating frame:', error);
      setSubmitError('Failed to create frame. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  }, [frameForm, featureDiagramId, snapshottedFrame, frames, dispatch]);

  const createNewFrame = useCallback(async () => {
    if (excalidrawAPI) {
      const appState = excalidrawAPI.getAppState();
      let selectedElementIds = Object.keys(appState.selectedElementIds);
      const allElements = excalidrawAPI.getSceneElements();
      const selectedFrames = allElements.filter(element => selectedElementIds.includes(element.id));
  
      const selectedFrameIds = selectedFrames.map(frame => frame.id);
      const selectedElements = allElements.filter(element => element.frameId && selectedFrameIds.includes(element.frameId));
      selectedElementIds = selectedElements.map(element => element.id);
  
      if (selectedElementIds.length === 0) {
        setShowWarning(true);
        setTimeout(() => setShowWarning(false), 3000);
        return;
      }
  
      try {
        const blob = await exportToBlob({
          elements: selectedElements,
          appState: excalidrawAPI.getAppState(),
          files: excalidrawAPI.getFiles(),
          mimeType: "image/png",
          quality: 1,
          exportPadding: 10,
        });
  
        const imageUrl = URL.createObjectURL(blob);
        setSnapshottedFrame({ elements: selectedElementIds, imageUrl, isPersisted: false });
        setShowFrameForm(true);
        setFrameForm(prev => ({
          ...prev,
          name: "New Frame",
          position: frames.length + 1
        }));
      } catch (error) {
        console.error('Error exporting frame as image:', error);
      }
    }
  }, [excalidrawAPI, frames.length]);

  const handleUpdateFramePosition = useCallback(async (frameId: number, newPosition: number) => {
    try {
      const updatedFrames = [...frames].sort((a, b) => {
        if (a.position === 0) return -1;
        if (b.position === 0) return 1;
        return a.position - b.position;
      });

      const oldIndex = updatedFrames.findIndex(frame => frame.id === frameId);
      const [movedFrame] = updatedFrames.splice(oldIndex, 1);
      updatedFrames.splice(newPosition, 0, movedFrame);

      const framesToUpdate = updatedFrames.map((frame, index) => ({
        id: frame.id,
        position: index === 0 ? 0 : index
      }));

      await dispatch(updateFramePositions({ featureDiagramId, frames: framesToUpdate })).unwrap();
    } catch (error) {
      console.error('Error updating frame positions:', error);
    }
  }, [frames, featureDiagramId, dispatch]);

  const handleUpdateFrame = useCallback(async (frameId: number, updateData: Partial<PersistedFrame>) => {
    try {
      await dispatch(updateFrame({ featureDiagramId, frameId, updateData })).unwrap();
      return true;
    } catch (error) {
      console.error('Error updating frame:', error);
      return false;
    }
  }, [featureDiagramId, dispatch]);

  const showFrame = useCallback((frameId: number) => {
    const frame = frames.find(f => f.id === frameId);
    if (frame) {
      window.open(frame.imageUrl, '_blank');
    }
  }, [frames]);

  return {
    selectedElements,
    previewImageUrl,
    persistedFrames: frames,
    snapshottedFrame,
    showFrameForm,
    frameForm,
    isSubmitting,
    submitError,
    showWarning,
    handleFrameFormChange,
    handleFrameFormSubmit,
    createNewFrame,
    showFrame,
    setShowFrameForm,
    showAiResponse,
    setShowAiResponse,
    deleteFrame: handleDeleteFrame,
    promptMessages,
    setPromptMessages,
    handlePromptSubmit,
    updateFramePosition: handleUpdateFramePosition,
    updateFrame: handleUpdateFrame,
    currentHtml,
    setCurrentHtml,
    isLoading,
    error,
  };
};

export default useFrames;