import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
import Dropzone from "dropzone"

export default class extends Controller {
  static targets = ["input", "preview", "newUploads"]
  static values = {
    url: String,
    postUrl: String,
    maxFileSize: Number,
    maxFiles: Number,
    acceptedFiles: String
  }

  connect() {
    this.dropZone = new Dropzone(this.element, {
      url: this.urlValue,
      paramName: "file",
      maxFilesize: this.maxFileSizeValue,
      maxFiles: this.maxFilesValue,
      acceptedFiles: this.acceptedFilesValue,
      autoQueue: false,
      addRemoveLinks: false,
      dictDefaultMessage: "Drop files here or click to upload",
      previewsContainer: this.previewTarget,
      clickable: this.element,
      previewTemplate: `
    <div class="dropzone-preview dz-preview dz-file-preview relative group w-[200px] image-preview relative group overflow-hidden rounded-md shadow-sm hover:shadow-md transition-shadow duration-200">
      <div class="dz-details">
        <div class="aspect-w-1 aspect-h-1">
          <img data-dz-thumbnail />
        </div>
        <div class="absolute flex flex-col gap-y-2 inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-200 flex items-center justify-center w-[200px]">
          <div class="dz-filename text-xs text-white"><span data-dz-name></span></div>
          <div class="dz-size text-xs text-white" data-dz-size></div>
        </div>
      </div>
    </div>
  `
    })

    this.dropZone.on("addedfile", this.handleAddedFile.bind(this))
    this.dropZone.on("removedfile", this.handleRemovedFile.bind(this))
  }

  handleAddedFile(file) {
    const formData = new FormData()
    formData.append('blob', file)
    
    // Remove 'hidden' class from preview and newUploads targets
    this.previewTarget.classList.remove('hidden')
    this.newUploadsTarget.classList.remove('hidden')
    console.log(`handleAddedFile: ${file}`)
    console.log(`postUrlValue: ${this.postUrlValue}`)

    fetch(this.postUrlValue, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      body: formData,
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        const hiddenField = document.createElement('input')
        hiddenField.type = 'hidden'
        hiddenField.name = 'ticket[document_ids][]'
        hiddenField.value = data.blob_id
        this.element.appendChild(hiddenField)
        file.blob = { signed_id: data.blob_id }
      } else {
        console.error('Upload failed:', data.error)
      }
    })
    .catch(error => {
      console.error('Error:', error)
    })
  }

  handleRemovedFile(file) {
    if (file.blob) {
      const hiddenField = this.element.querySelector(`input[value="${file.blob.signed_id}"]`)
      if (hiddenField) {
        hiddenField.remove()
      }
    }
  }
}