import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["nameField", "descriptionField", "addUserRoleButton", "remaining"];

  connect() {
    this.updateButtonStatus();
  }

  updateButtonStatus() {
    if (!this.hasNameFieldTarget) return;

    const characterLength = parseInt(this.data.get('character-length') || "0", 10);

    if (
      this.nameFieldTarget.value.trim() !== "" &&
      this.descriptionFieldTarget.value.trim() !== "" &&
      (characterLength === 0 || this.descriptionFieldTarget.value.length >= characterLength)
    ) {
      this.addUserRoleButtonTarget.disabled = false;
    } else {
      this.addUserRoleButtonTarget.disabled = true;
    }

    // update remaining characters if characterLength is greater than zero and remaining target exists
    if (characterLength > 0 && this.hasRemainingTarget) {
      const remainingChars = this.descriptionFieldTarget.value.length;
      this.remainingTarget.innerText = `Remaining characters required: ${remainingChars >= 0 ? remainingChars : 0}/${characterLength}`;
    }
  }
}
