// app/javascript/components/ExcalidrawComponent.tsx
// this is the entrypoint for the ExcalidrawApp
import React, { useMemo, useCallback } from 'react';
import { Excalidraw, MainMenu, Sidebar, Footer, WelcomeScreen } from "@excalidraw/excalidraw";
import { useExcalidrawData } from "./Excalidraw/hooks/useExcalidrawData";
import SlideOver from "./Excalidraw/components/SlideOver";
import { CodeBracketIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import store from './Excalidraw/stores/stores';
import { Provider } from 'react-redux';
import { setupCSRF } from '../config/axiosConfig';

interface ExcalidrawComponentProps {
    featureId: string;
    featurePath: string;
    csrfToken: string;
    diagramId: string;
}

function ExcalidrawComponent({ featureId, featurePath, csrfToken, diagramId }: ExcalidrawComponentProps) {
    const {
        sceneData,
        excalidrawKey,
        isGeneratingDiagram,
        setExcalidrawAPI,
        handleChange,
        fetchFeatureDiagram,
        fetchDiagramToHtml,
        isGeneratingHtml,
        exportToImage,
        slideOverOpen,
        setSlideOverOpen,
        excalidrawAPI,
    } = useExcalidrawData({ featureId, diagramId });
    setupCSRF(csrfToken);

    const memoizedMainMenu = useMemo(() => (
        <MainMenu>
            <MainMenu.ItemLink href={`${featurePath}`}>
                Back to Feature
            </MainMenu.ItemLink>
            <MainMenu.Item onSelect={() => window.location.href = `/drawing/show/${featureId}`}>
                Go Full Screen
            </MainMenu.Item>
            <MainMenu.DefaultItems.SaveToActiveFile />
            <MainMenu.DefaultItems.Export />
            <MainMenu.DefaultItems.SaveAsImage />
            <MainMenu.DefaultItems.Help />
            <MainMenu.DefaultItems.ClearCanvas />
            <MainMenu.Separator />
            <MainMenu.Separator />
            <MainMenu.DefaultItems.ToggleTheme />
            <MainMenu.DefaultItems.ChangeCanvasBackground />
        </MainMenu>
    ), [featurePath]);

    const memoizedSidebar = useMemo(() => (
        <Sidebar name="custom">
            <Sidebar.Header />
            <Sidebar.Tabs style={{ padding: "0.5rem" }}>
                <Sidebar.Tab tab="one">Tab one!</Sidebar.Tab>
                <Sidebar.Tab tab="two">Tab two!</Sidebar.Tab>
                <Sidebar.TabTriggers>
                    <Sidebar.TabTrigger tab="one">One</Sidebar.TabTrigger>
                    <Sidebar.TabTrigger tab="two">Two</Sidebar.TabTrigger>
                </Sidebar.TabTriggers>
            </Sidebar.Tabs>
        </Sidebar>
    ), []);

    return (
        <Provider store={store}>
            <Excalidraw
                key={excalidrawKey}
                initialData={sceneData}
                onChange={handleChange}
                excalidrawAPI={(api) => setExcalidrawAPI(api)}
                aiEnabled={true}
            >
                {memoizedMainMenu}
                <WelcomeScreen>
                    <WelcomeScreen.Center>
                        <WelcomeScreen.Center.Heading>
                            GoScope Feature Canvas
                        </WelcomeScreen.Center.Heading>
                        <WelcomeScreen.Center.Menu>
                            <WelcomeScreen.Center.MenuItemLink href="#">
                                Start drawing
                            </WelcomeScreen.Center.MenuItemLink>
                            <WelcomeScreen.Center.MenuItemHelp />
                        </WelcomeScreen.Center.Menu>
                    </WelcomeScreen.Center>
                </WelcomeScreen>
                {memoizedSidebar}


                {excalidrawAPI && <SlideOver
                    isOpen={slideOverOpen}
                    onClose={() => setSlideOverOpen(false)}
                    featureId={featureId}
                    featurePath={featurePath}
                    featureDiagramId={diagramId}
                    onGenerateHtml={() => fetchDiagramToHtml()}
                    excalidrawAPI={excalidrawAPI}
                />}

                <Footer>
                    <button
                        className="btn btn-primary mx-2"
                        onClick={() => fetchFeatureDiagram(true)}
                        disabled={isGeneratingDiagram}
                    >
                        {isGeneratingDiagram
                            ? "Generating AI Flow Diagram..."
                            : "Generate Feature Diagram (AI)"}
                    </button>
                    <button
                        className="btn btn-primary mx-2"
                        onClick={exportToImage}
                    >
                        <ArrowDownTrayIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                        Export as Image
                    </button>
                    {/* Open Slideover Button */}
                    <button
                        className={`btn btn-primary mx-2 ${slideOverOpen ? 'bg-green-600' : 'bg-green-400'
                            } inline-flex items-center transition-colors duration-300`}
                        onClick={() => setSlideOverOpen(true)}
                    >
                        <CodeBracketIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                        Frames <span style={{ fontFamily: 'Roboto, sans-serif', fontSize: '0.75rem', color: 'white', marginLeft: '8px' }}>(AI)</span>
                    </button>
                </Footer>
            </Excalidraw>
        </Provider>
    );
}

export default ExcalidrawComponent;