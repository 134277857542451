import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["expandableContent", "card", "ticketList"]

  connect() {
    console.log("FeatureCardController connected")
    this.expanded = false
    this.expandableContentTarget.style.maxHeight = "0"
    this.expandableContentTarget.style.overflow = "hidden"
    this.initializeSortable()
  }

  initializeSortable() {
    if (this.hasTicketListTarget) {
      new Sortable(this.ticketListTarget, {
        animation: 150,
        onEnd: this.updatePositions.bind(this)
      })
    }
  }

  toggleExpand(event) {
    this.expanded = !this.expanded

    if (this.expanded) {
      this.cardTarget.classList.add("min-h-[16rem]")
      this.expandableContentTarget.classList.remove("hidden")
      this.expandableContentTarget.style.maxHeight = `${this.expandableContentTarget.scrollHeight}px`
    } else {
      this.cardTarget.classList.remove("min-h-[16rem]")
      this.expandableContentTarget.style.maxHeight = "0"
    }

    // Rotate the chevron icon
    event.currentTarget.querySelector('svg').style.transform = this.expanded ? 'rotate(180deg)' : 'rotate(0)'
  }

  createTicket(event) {
    event.preventDefault()
    const featureId = this.element.dataset.id
    const url = `/features/${featureId}/create_ticket`
    
    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ feature_id: featureId })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        // Optionally, you can update the UI to show the new ticket
        console.log('Ticket created successfully')
      } else {
        console.error('Failed to create ticket')
      }
    })
    .catch(error => console.error('Error:', error))
  }
}