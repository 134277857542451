import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scrollcomments"
export default class extends Controller {
  static targets = [ "commentslist" ]

  // User MutationObserver to detect changes in commentslistTarget
  // and reset scroll to bottom of commentslistTarget
  connect() {
    // console.log("ScrollCommentsController connected")
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === "childList") {
          this.resetScroll()
        }
      })
    })

    observer.observe(this.commentslistTarget, {
      childList: true
    })

    this.resetScroll()
  }

  // Remove observer on disconnect
  disconnect() {
    // console.log("ScrollCommentsController disconnected")
    // observer.disconnect()
  }

  // Reset scroll to bottom of commentslistTarget
  resetScroll() {
    // console.log("ScrollCommentsController resetScroll")
    this.commentslistTarget.scrollTop = this.commentslistTarget.scrollHeight
  }
}
