// app/javascript/controllers/ai_assistant_controller.js

import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

// used primarily by products/_assistant_form.html.erb
export default class extends Controller {
  static targets = ["select", "featureInstructions", "requirementInstructions", "descriptionPromptInstructions", "featureSpinner", "requirementSpinner", "descriptionSpinner", "selectSpinner", "descriptionPromptSpinner", "featurePromptSpinner", "requirementPromptSpinner", "descriptionPromptIcon", "featurePromptIcon", "requirementPromptIcon", "descriptionMessageField", "featureMessageField", "requirementMessageField"]
  static values = { productId: String, enablePaidFeatures: Boolean }

  connect() {
    this.debounceTimers = {}
    this.setupSubscription()
  }

  setupSubscription() {
    this.subscription = consumer.subscriptions.create("AiImprovePromptChannel", {
      connected: () => console.log("Connected to AiImprovePromptChannel"),
      disconnected: () => console.log("Disconnected from AiImprovePromptChannel"),
      received: (data) => this.handleImprovedInstructions(data)
    })
  }

  updateSelectedAssistant() {
    this.showSpinner(this.selectSpinnerTarget)
    const selectedAssistantId = this.selectTarget.value
    this.updateUserConfig('selected_assistant', selectedAssistantId)
      .then(success => this.showResult(this.selectSpinnerTarget, success))
  }

  updateFeatureInstructions() {
    this.showSpinner(this.featureSpinnerTarget)
    this.debounce('feature_instructions', () => {
      const instructions = this.featureInstructionsTarget.value
      this.updateUserConfig('feature_instructions', instructions)
        .then(success => this.showResult(this.featureSpinnerTarget, success))
    }, 800)
  }

  updateRequirementInstructions() {
    this.showSpinner(this.requirementSpinnerTarget)
    this.debounce('requirement_instructions', () => {
      const instructions = this.requirementInstructionsTarget.value
      this.updateUserConfig('requirement_instructions', instructions)
        .then(success => this.showResult(this.requirementSpinnerTarget, success))
    }, 800)
  }

  updateDescriptionPromptInstructions() {
    this.showSpinner(this.descriptionSpinnerTarget)
    this.debounce('description_prompt_instructions', () => {
      const instructions = this.descriptionPromptInstructionsTarget.value
      this.updateUserConfig('description_prompt_instructions', instructions)
        .then(success => this.showResult(this.descriptionSpinnerTarget, success))
    }, 800)
  }

  debounce(key, func, delay) {
    clearTimeout(this.debounceTimers[key])
    this.debounceTimers[key] = setTimeout(func, delay)
  }

  showSpinner(target) {
    target.querySelector('svg:nth-child(1)').classList.remove('hidden')
    target.querySelector('svg:nth-child(2)').classList.add('hidden')
    target.querySelector('svg:nth-child(3)').classList.add('hidden')
    target.classList.remove('hidden')
  }

  showResult(target, success) {
    target.querySelector('svg:nth-child(1)').classList.add('hidden')
    target.querySelector('svg:nth-child(2)').classList.toggle('hidden', !success)
    target.querySelector('svg:nth-child(3)').classList.toggle('hidden', success)
    
    setTimeout(() => {
      target.classList.add('hidden')
    }, 2000)
  }

  async updateUserConfig(configType, value) {
    let url, body;

    if (configType === 'selected_assistant') {
      url = '/ai_assistants/selected_assistant';
      body = JSON.stringify({ selected_assistant_id: value, product_id: this.productIdValue });
    } else {
      url = `/ai_assistants/${configType}`;
      body = JSON.stringify({ [configType]: value, product_id: this.productIdValue });
    }

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: body
      });

      if (response.ok) {
        console.log(`${configType} updated successfully`);
        return true;
      } else {
        console.error(`Failed to update ${configType}`);
        return false;
      }
    } catch (error) {
      console.error(`Error updating ${configType}:`, error);
      return false;
    }
  }

  handleImprovedInstructions(data) {
    const { config_type, improved_instructions, error, error_details } = data
    let instructionsTarget;
    let iconTarget;
    let spinnerTarget;
    let messageFieldTarget;

    switch (config_type) {
      case 'description_prompt_instructions':
        spinnerTarget = this.descriptionPromptSpinnerTarget;
        iconTarget = this.descriptionPromptIconTarget;
        instructionsTarget = this.descriptionPromptInstructionsTarget;
        messageFieldTarget = this.descriptionMessageFieldTarget;
        break;
      case 'feature_instructions':
        spinnerTarget = this.featurePromptSpinnerTarget;
        iconTarget = this.featurePromptIconTarget;
        instructionsTarget = this.featureInstructionsTarget;
        messageFieldTarget = this.featureMessageFieldTarget;
        break;
      case 'requirement_instructions':
        spinnerTarget = this.requirementPromptSpinnerTarget;
        iconTarget = this.requirementPromptIconTarget;
        instructionsTarget = this.requirementInstructionsTarget;
        messageFieldTarget = this.requirementMessageFieldTarget;
        break;
      default:
        console.error(`Unknown config_type: ${config_type}`);
        return;
    }

    if (error) {
      this.showMessage(messageFieldTarget, error, 'bg-red-400', 15000)
      console.log("Error details: ", error_details)
      iconTarget.classList.remove('hidden')
      spinnerTarget.classList.add('hidden')
      instructionsTarget.disabled = false
      instructionsTarget.style.opacity = '1'
      instructionsTarget.style.pointerEvents = 'auto'

      return
    }

    if (instructionsTarget && spinnerTarget) {
      instructionsTarget.value = improved_instructions;
      
      this.updateUserConfig(config_type, improved_instructions);
      this.showResult(spinnerTarget, true);
      this.highlightChanges(instructionsTarget);

      iconTarget.classList.remove('hidden')
      spinnerTarget.classList.add('hidden')
      instructionsTarget.disabled = false
      instructionsTarget.style.opacity = '1'
      instructionsTarget.style.pointerEvents = 'auto'
    } else {
      console.error(`Target not found for config_type: ${config_type}`);
      messageFieldTarget.classList.remove('hidden')
      this.showMessage(messageFieldTarget, 'Uh oh, something went wrong. Please try again. If the problem persists, please contact support.', 'bg-red-400', 7000)
    }
  }

  highlightChanges(target) {
    target.style.backgroundColor = '#fffacd'; // Light yellow
    setTimeout(() => {
      target.style.backgroundColor = '';
    }, 3000);
  }

  async improvePrompt(event) {
    const configType = event.currentTarget.dataset.configType
    let spinnerTarget;
    let iconTarget;
    let instructionsTarget;
    let messageFieldTarget;
    let context;

    switch (configType) {
      case 'description_prompt_instructions':
        spinnerTarget = this.descriptionPromptSpinnerTarget;
        iconTarget = this.descriptionPromptIconTarget;
        instructionsTarget = this.descriptionPromptInstructionsTarget;
        messageFieldTarget = this.descriptionMessageFieldTarget;
        context = 'Write a prompt that can be used to give an AI. The goal of the prompt is to write a meaningful prompt that can be used to generate a description for a product.'
        break;
      case 'feature_instructions':
        spinnerTarget = this.featurePromptSpinnerTarget;
        iconTarget = this.featurePromptIconTarget;
        instructionsTarget = this.featureInstructionsTarget;
        messageFieldTarget = this.featureMessageFieldTarget;
        context = 'Write a prompt that can be used to give an AI. The goal of the prompt is to write a meaningful prompt that can be used to generate a description for a feature of a product.'
        break;
      case 'requirement_instructions':
        spinnerTarget = this.requirementPromptSpinnerTarget;
        iconTarget = this.requirementPromptIconTarget;
        instructionsTarget = this.requirementInstructionsTarget;
        messageFieldTarget = this.requirementMessageFieldTarget;
        context = 'Write a prompt that can be used to give an AI. The goal of the prompt is to write a meaningful prompt that can be used to generate a description for a requirement of a feature of a product.'
        break;
      default:
        console.error(`Unknown configType: ${configType}`);
        return;
    }
    
    if (!this.enablePaidFeaturesValue) {
      this.showMessage(messageFieldTarget, 'You need to subscribe to access this feature.', 'bg-red-400', 7000)
      return
    }

    this.showSpinner(spinnerTarget)
    iconTarget.classList.add('hidden')
    instructionsTarget.disabled = true
    instructionsTarget.style.opacity = '0.5'
    instructionsTarget.style.pointerEvents = 'none'
    this.showMessage(messageFieldTarget, 'Improving prompt, please wait...', 'bg-blue-400', 7000)
    try {
      const response = await fetch(`/ai_assistants/improve_prompt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({
          config_type: configType,
          instructions: instructionsTarget.value,
          context: context,
          product_id: this.productIdValue
        })
      })

      if (!response.ok) {
        // throw new Error('Failed to improve prompt')
      }
      
      // The actual update will happen when we receive the improved instructions via ActionCable
    } catch (error) {
      console.error(`Error improving ${configType}:`, error)
      this.showResult(spinnerTarget, false)
    }
  }

  showMessage(messageField, message, styling, duration) {
    messageField.textContent = message
    messageField.classList.remove('hidden')
    messageField.classList.add(styling)

    setTimeout(() => {
      messageField.classList.add('hidden')
      messageField.classList.remove(styling)
    }, duration)
  }
}