// app/javascript/services/framesService.ts

import { FrameFormData, Frame, PersistedFrame } from '../components/Excalidraw/types/frameTypes';
import axiosInstance from '../config/axiosConfig';

const API_BASE_URL = '/feature_diagrams';

export const framesService = {
  fetchFrames: async (featureDiagramId: string): Promise<PersistedFrame[]> => {
    try {
      const response = await axiosInstance.get(`${API_BASE_URL}/${featureDiagramId}/fetch_frames`);
      
      return response.data.frames.map((frame: any) => ({
        ...frame, isPersisted: true,
        elements: JSON.parse(frame.elements || '[]'),
        frame_htmls: frame.frame_htmls || [],
      }));
    } catch (error) {
      console.error('Error fetching frames:', error);
      throw error;
    }
  },

  createFrame: async (featureDiagramId: string, frameData: FrameFormData): Promise<PersistedFrame> => {
    try {
      const response = await axiosInstance.post<{success: boolean, message: string, frame: PersistedFrame}>(
        `${API_BASE_URL}/${featureDiagramId}/create_frame`,
        frameData, { headers: { 'Content-Type': 'application/json' } }
      );

      if (response.data.success) {
        return {
          ...response.data.frame,
          isPersisted: true,
          elements: JSON.parse(response.data.frame.elements || '[]'),
          frame_htmls: [],
        };
      } else {
        throw new Error(response.data.message || 'Failed to create frame');
      }
    } catch (error) {
      console.error('Error creating frame:', error);
      throw error;
    }
  },

  deleteFrame: async (featureDiagramId: string, frameId: number): Promise<void> => {
    try {
      if (!featureDiagramId || !frameId) {
        console.warn('Warning: Failed to delete frame: featureDiagramId and frameId must be provided. featureDiagramId:', featureDiagramId, 'frameId:', frameId);
        return;
      }

      await axiosInstance.delete(`${API_BASE_URL}/${featureDiagramId}/delete_frame`, {
        data: { frame_id: frameId },
        headers: { 'Content-Type': 'application/json' },
      });
    } catch (error) {
      console.error('Error deleting frame:', error);
      throw error;
    }
  },

  updateFramePositions: async (featureDiagramId: string, frames: { id: number, position: number }[]): Promise<void> => {
    try {
      await axiosInstance.patch(`${API_BASE_URL}/${featureDiagramId}/update_frame_positions`, {
        frames: frames
      }, { headers: { 'Content-Type': 'application/json' } });
    } catch (error) {
      console.error('Error updating frame positions:', error);
      throw error;
    }
  },

  updateFrame: async (featureDiagramId: string, frameId: number, updateData: Partial<PersistedFrame>): Promise<boolean> => {
    try {
      const dataToSend = { ...updateData };
      if (dataToSend.elements) { dataToSend.elements = JSON.stringify(dataToSend.elements) }

      const response = await axiosInstance.patch(
        `${API_BASE_URL}/${featureDiagramId}/update_frame`,
        { frame_id: frameId, ...dataToSend },
        { headers: { 'Content-Type': 'application/json' } }
      );

      return response.data.success;
    } catch (error) {
      console.error('Error updating frame:', error);
      return false;
    }
  },

  saveFrameHtml: async (featureFrameId: number, html: string): Promise<void> => {
    try {
      const currentUrl = window.location.origin;
      await axiosInstance.post(
        // /feature_frames/:feature_frame_id/frame_htmls(
        `/feature_frames/${featureFrameId}/frame_htmls`,
        { html },
        { headers: { 'Content-Type': 'application/json' } }
      );
    } catch (error) {
      console.error('Error saving frame HTML:', error);
      throw error;
    }
  },
};