import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="spinner"
export default class extends Controller {
  static targets = [ "spinner" ]

  connect() {
    // console.log({ spinner: "connect", this: this })
  }

  start(event) {
    console.log("Spinner Starting")
    console.log({ spinner: "start", event: event, this: this })
    this.spinnerTarget.classList.remove("hidden")
  }

  disconnect() {
    // console.log({ spinner: "disconnect", this: this })
    this.spinnerTarget.classList.add("hidden")
  }
}
