// app/javascript/controllers/tickets/ticket_list_controller.js

import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["ticketList"]
  static values = {
    updatePositionsUrl: String
  }

  connect() {
    console.log("TicketListController connected")
    this.initializeSortable()
  }

  initializeSortable() {
    new Sortable(this.ticketListTarget, {
      animation: 150,
      onEnd: this.updatePositions.bind(this)
    })
  }

  updatePositions(event) {
    const tickets = Array.from(this.ticketListTarget.children)
    const updatedPositions = tickets.map((ticket, index) => ({
      id: ticket.dataset.ticketId,
      position: index + 1
    }))

    fetch(this.updatePositionsUrlValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ positions: updatedPositions })
    })
    .then(response => response.json())
    .then(data => {
      console.log('Positions updated successfully', data)
    })
    .catch(error => {
      console.error('Error updating positions:', error)
    })
  }
}