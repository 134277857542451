import React, { useState, useCallback, useMemo } from 'react';
import { ClipboardDocumentIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { AnthropicResponse } from '../../types';
import DOMPurify = require('dompurify');
import { ExcalidrawImperativeAPI } from '@excalidraw/excalidraw/types/types';

interface GeneratingHtmlTabProps {
  onGenerateHtml: (inputText: string) => Promise<AnthropicResponse>;
  excalidrawAPI: ExcalidrawImperativeAPI;
  featureDiagramId: string;
  featureId: string;
}

const GeneratingHtmlTab = ({ onGenerateHtml, excalidrawAPI, featureDiagramId, featureId }: GeneratingHtmlTabProps) => {
  const defaultResponseText = '// Generated HTML will appear here';
  const [inputText, setInputText] = useState('');
  const [responseText, setResponseText] = useState(defaultResponseText);
  const [isGeneratingHtml, setIsGeneratingHtml] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputText(e.target.value);
  }, []);

  const sanitizedHtml = useMemo(() => {
    return DOMPurify.sanitize(responseText);
  }, [responseText]);

  const handleSubmit = useCallback(async () => {
    try {
      setIsGeneratingHtml(true);
      const result = await onGenerateHtml(inputText);
      const html = result.html.content[0].text;
      console.log("Debug result: ", html);

      if (result.success) {
        setResponseText(html);
      } else {
        setResponseText("Failed to generate HTML. Please try again.");
      }
    } catch (error) {
      console.error("Error generating HTML:", error);
      setResponseText("An error occurred while generating HTML. Please try again.");
    } finally {
      setIsGeneratingHtml(false);
    }
  }, [inputText, onGenerateHtml]);

  const handleCopy = useCallback(() => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  }, []);

  return (
    <>
      <div className="mb-4 bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 rounded-md flex items-center justify-between text-sm">
        <div>
          <p>Enter your technical requirements for converting Excalidraw to HTML using Tailwind CSS and React.</p>
        </div>
      </div>
      <textarea
        className="mt-4 w-full h-32 p-2 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
        value={inputText}
        onChange={handleInputChange}
        placeholder="Enter technical requirements..."
      />
      <div className="mt-4 relative">
        <div className="absolute top-2 right-2 z-10">
          <CopyToClipboard text={responseText} onCopy={handleCopy}>
            <button className="p-2 bg-gray-800 text-green-400 rounded-md hover:bg-gray-700 transition-colors">
              {isCopied ? (
                <span className="text-green-400">Copied!</span>
              ) : (
                <ClipboardDocumentIcon className="h-5 w-5 text-green-400" />
              )}
            </button>
          </CopyToClipboard>
        </div>
        <SyntaxHighlighter
          language="html"
          style={atomOneDark}
          customStyle={{
            height: '28rem',
            borderRadius: '0.375rem',
            padding: '1rem',
            fontSize: '0.875rem',
          }}
        >
          {responseText}
        </SyntaxHighlighter>
      </div>
      {/* Response Preview */}
      <div className="mt-8">
        <div className="mb-4 bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 rounded-md flex items-center justify-between text-sm">
          <div>
            <p className="font-bold">Info</p>
            <p>This preview only works with HTML responses using Tailwind CSS.</p>
          </div>
          <span className="bg-blue-500 text-white text-xs font-semibold px-2 py-1 rounded-full">Beta</span>
        </div>
        <h3 className="text-lg font-medium text-gray-900">Preview</h3>
        {responseText === defaultResponseText ? (
          <div className="mt-4 border rounded-md p-4 max-h-[40rem] overflow-y-scroll">
            <div className="animate-pulse space-y-4">
              <div className="h-4 bg-gray-200 rounded w-3/4"></div>
              <div className="h-4 bg-gray-200 rounded"></div>
              <div className="h-4 bg-gray-200 rounded w-5/6"></div>
              <div className="h-4 bg-gray-200 rounded w-5/6"></div>
            </div>
          </div>
        ) : (
          <div className="mt-4 border rounded-md p-4">
            <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
          </div>
        )}
      </div>
      <div className="mt-4 flex justify-end">
        <button
          type="button"
          className={`ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isGeneratingHtml ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={handleSubmit}
          disabled={isGeneratingHtml}
        >
          {isGeneratingHtml ? (
            <>
              <ArrowPathIcon className="animate-spin -ml-1 mr-2 h-5 w-5 text-white" />
              Generating...
            </>
          ) : (
            'Diagram to HTML'
          )}
        </button>
      </div>
    </>
  );
};

export default React.memo(GeneratingHtmlTab);