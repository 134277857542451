import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="comments"
export default class extends Controller {
  static targets = [ "comment" ]
  static values = { senderuser: String }

  connect() {
    let currentuser = document.getElementById("comments_current_user")

    if (currentuser.innerHTML != this.senderuserValue){
      this.commentTarget.classList.add("hidden")
    }
  }
}
