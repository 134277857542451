import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["drawer", "overlay"]

  connect() {
    // console.log("RightDrawerController connected")
  }

  open() {
    this.drawerTarget.classList.remove("translate-x-full")
    this.drawerTarget.classList.add("translate-x-0")
    this.overlayTarget.classList.remove("hidden")
  }

  close() {
    this.drawerTarget.classList.remove("translate-x-0")
    this.drawerTarget.classList.add("translate-x-full")
    this.overlayTarget.classList.add("hidden")
  }
}