import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-select-all"
export default class extends Controller {
  static targets = ["greatgrandparent", "grandparent", "parent", "child"]
  connect() {
    // console.log("checkbox-select-all connected")
  }

  toggleRequirement(event) {
    let feature = event.currentTarget.dataset.feature
    let role = event.currentTarget.dataset.role
    let ability = event.currentTarget.dataset.ability

    this.checkRequirements(feature, role, ability)
    this.checkRoles(feature, role)
    this.checkFeatures(feature)
    this.selected_checkbox_counter()
  }

  checkRequirements(feature, role, ability) {
    let requirements = document.querySelectorAll(`[data-feature="${feature}"][data-role="${role}"][data-ability="${ability}"][data-checkbox-select-all-target="child"]`)
    let abilities = document.querySelectorAll(`[data-feature="${feature}"][data-role="${role}"][data-ability="${ability}"][data-checkbox-select-all-target="parent"]`)

    let checked = true
    requirements.forEach(requirement => {
      if (!requirement.checked) {
        checked = false
      }
    })
    abilities[0].checked = checked
  }

  toggleAction(event) {
    let feature = event.currentTarget.dataset.feature
    let role = event.currentTarget.dataset.role
    let ability = event.currentTarget.dataset.ability
    let checked = event.currentTarget.checked

    this.toggleRequirementsFor(feature, role, ability, checked)
    this.checkRoles(feature, role)
    this.checkFeatures(feature)
    this.selected_checkbox_counter()
  }

  toggleRequirementsFor(feature, role, ability, checked) {
    let abilities = document.querySelectorAll(`[data-feature="${feature}"][data-role="${role}"][data-ability="${ability}"]`)
    abilities.forEach(ability => {
      ability.checked = checked
    })
  }

  checkRoles(feature, role) {
    let abilities = document.querySelectorAll(`[data-feature="${feature}"][data-role="${role}"][data-checkbox-select-all-target="parent"]`)
    let roles = document.querySelectorAll(`[data-feature="${feature}"][data-role="${role}"][data-checkbox-select-all-target="grandparent"]`)

    let checked = true
    abilities.forEach(ability => {
      if (!ability.checked) {
        checked = false
      }
    })
    roles[0].checked = checked
  }

  toggleRole(event) {
    let feature = event.currentTarget.dataset.feature
    let role = event.currentTarget.dataset.role

    let checked = event.currentTarget.checked;

    this.toggleActionsFor(feature, role, checked)
    this.checkFeatures(feature)
    this.selected_checkbox_counter()
  }

  toggleActionsFor(feature, role, checked) {
    let roles = document.querySelectorAll(`[data-feature="${feature}"][data-role="${role}"]`)
    roles.forEach(role => {
      role.checked = checked
    })
  }

  checkFeatures(feature) {
    let roles = document.querySelectorAll(`[data-feature="${feature}"][data-checkbox-select-all-target="grandparent"]`)
    let features = document.querySelectorAll(`[data-feature="${feature}"][data-checkbox-select-all-target="greatgrandparent"]`)

    let checked = true
    roles.forEach(role => {
      if (!role.checked) {
        checked = false
      }
    })
    features[0].checked = checked
  }

  toggleFeature(event) {
    let feature = event.currentTarget.dataset.feature;
    let checked = event.currentTarget.checked;

    this.toggleAllFor(feature);
    this.selected_checkbox_counter();

    let [scope, featureName, featureId] = feature.split('_');
    let nestedDataFrame = document.querySelector(`#nested-data-${scope}-${featureId}`);

    console.log('Nested data: ', `#nested-data-${scope}-${featureId}`);

    if (nestedDataFrame && nestedDataFrame.getAttribute('loading') === 'lazy' && nestedDataFrame.querySelectorAll('div').length === 0) {
      nestedDataFrame.addEventListener('turbo:frame-load', () => {
        this.toggleAllFor(feature, checked);
        this.selected_checkbox_counter();

        // Expand the accordion
        let accordionButton = document.querySelector(`[aria-controls="accordion-collapse-body-${scope}-${featureId}"]`);
        if (accordionButton) {
          accordionButton.setAttribute('aria-expanded', 'true');
        }

      }, { once: true });
    } else if (nestedDataFrame) {
      this.toggleAllFor(feature, checked);
      this.selected_checkbox_counter();
    }
  }

  toggleAllFor(feature, checked) {
    let features = document.querySelectorAll(`[data-feature="${feature}"]`);
    features.forEach(feature => {
      feature.checked = checked;
    });
  }

  onNestedDataLoaded(event) {
    let nestedDataFrame = event.target;
    let feature = nestedDataFrame.dataset.checkboxSelectAllFeatureValue;
    let greatgrandparentCheckbox = document.querySelector(`[data-feature="${feature}"][data-checkbox-select-all-target="greatgrandparent"]`);

    if (greatgrandparentCheckbox.checked) {
      this.toggleAllFor(feature);
    }

    this.selected_checkbox_counter();
  }

  selected_checkbox_counter() {
    let scoped_list = document.querySelector('#scoped_list')
    let scoped_checkboxes = scoped_list.querySelectorAll(`[data-checkbox-select-all-target="child"]`)

    let scoped_counter = 0
    scoped_checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        scoped_counter++
      }
    })

    this.set_counter_value(scoped_counter, "scoped_list")

    let unscoped_list = document.querySelector('#unscoped_list')
    let unscoped_checkboxes = unscoped_list.querySelectorAll(`[data-checkbox-select-all-target="child"]`)
    // console.log(unscoped_checkboxes)
    let unscoped_counter = 0
    unscoped_checkboxes.forEach(checkbox => {
      // console.log("checkbox:" + checkbox.checked)
      if (checkbox.checked) {
        unscoped_counter++
        // console.log("Unscoped counter:" + unscoped_counter)
      }
    })
    this.set_counter_value(unscoped_counter, "unscoped_list")
  }

  set_counter_value(counter, scoped) {
    let counter_div = '';
    let button = '';

    if (scoped == "scoped_list") {
      counter_div = document.getElementById('scoped-selected-checkbox-counter');
      button = document.getElementById('scoped-button');
    } else {
      counter_div = document.getElementById('unscoped-selected-checkbox-counter');
      button = document.getElementById('unscoped-button');
    }
    console.log(button)
    console.log(counter)
    if (counter == 0) {
      counter_div.innerHTML = ``;
      button.classList.add('hidden');
    } else {
      counter_div.innerHTML = `<strong>${counter} Requirement(s) Selected</strong>`;
      button.classList.remove('hidden');
    }
  }

  // toggleHideOutOfScope() {
  //   const uncheckedRequirements = this.element.querySelectorAll('[data-checkbox-select-all-target="child"]:not(:checked)');
  //
  //   uncheckedRequirements.forEach(requirement => {
  //     const parentElement = requirement.closest('[data-checkbox-select-all-target="parent"]');
  //     if (parentElement) {
  //       parentElement.classList.toggle('hidden');
  //     }
  //   });
  // }
}