// app/javascript/components/Excalidraw/components/SlideOver/FramesTab.tsx

import React, { useEffect, useCallback, useMemo } from 'react';
import FrameForm from './FramesTab/FrameForm';
import FramesGuide from './FramesTab/FramesGuide';
import useFrames from '../../hooks/useFrames';
import ManageFramesModal from '../ManageFrames/ManageFramesModal';
import CircularProgress from '@mui/material/CircularProgress';
import GeneratedHtml from '../GeneratedHtml';
import Frame from '../ManageFrames/Frame';
import { FramesTabProps, PersistedFrame } from '../../types/frameTypes';
import { Box, Typography, Button, Chip, Alert, Paper } from '@mui/material';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import useGenerateHTML from '../../hooks/useGenerateHTML';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSelector, useDispatch } from 'react-redux';
import { 
  selectFrames, 
  selectSelectedFrame, 
  selectIsManageFramesModalOpen 
} from '../../stores/selectors/framesSelector';
import { 
  setSelectedFrameId, 
  setIsManageFramesModalOpen,
  fetchFrames,
  updateFramePositions
} from '../../stores/slices/framesSlice';

const FramesTab: React.FC<FramesTabProps> = ({ excalidrawAPI, featureDiagramId, featureId }) => {
  const isManageFramesModalOpen = useSelector(selectIsManageFramesModalOpen);

  const dispatch = useDispatch();
  const frames = useSelector(selectFrames);
  const selectedFrame = useSelector(selectSelectedFrame);

  const {
    persistedFrames,
    snapshottedFrame,
    showFrameForm,
    frameForm,
    isSubmitting,
    submitError,
    showWarning,
    handleFrameFormChange,
    handleFrameFormSubmit,
    createNewFrame,
    showFrame,
    deleteFrame,
    setShowFrameForm,
    showAiResponse,
    setShowAiResponse,
    promptMessages,
    handlePromptSubmit,
    updateFrame,
    updateFramePosition,
    currentHtml,
    setCurrentHtml,
  } = useFrames(excalidrawAPI, featureDiagramId, featureId);

  const {
    responseText,
    sanitizedHtml,
    isGeneratingHtml,
    setIsGeneratingHtml,
    fetchDiagramToHtml,
  } = useGenerateHTML(excalidrawAPI, featureDiagramId, featureId);

  useEffect(() => {
    dispatch(fetchFrames(featureDiagramId));
  }, [dispatch, featureDiagramId]);

  const onDragEnd = useCallback(async (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(frames);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedItems = items.map((item, index) => ({
      id: item.id,
      position: index === 0 ? 0 : index,
    }));

    dispatch(updateFramePositions({ featureDiagramId, frames: updatedItems }));
  }, [frames, featureDiagramId, dispatch]);

  const handleFrameClick = useCallback((frame: PersistedFrame) => {
    dispatch(setSelectedFrameId(frame.id));
    setShowAiResponse(Boolean(frame.frame_htmls && frame.frame_htmls.length > 0));
  }, [dispatch]);

  const handleUpdateFrame = useCallback(async (frameId: number, updateData: Partial<PersistedFrame>) => {
    try {
      await dispatch(updateFrame({ featureDiagramId, frameId, updateData })).unwrap();
      return true;
    } catch (error) {
      console.error('Failed to update frame:', error);
      return false;
    }
  }, [featureDiagramId, dispatch]);

  const handleDeleteFrame = useCallback(async (frameId: number) => {
    try {
      await dispatch(deleteFrame({ featureDiagramId, frameId })).unwrap();
      return true;
    } catch (error) {
      console.error('Failed to delete frame:', error);
      return false;
    }
  }, [featureDiagramId, dispatch]);

  const handleGenerateHTML = useCallback(async (frameId: number) => {
    console.log("Match selectedFrame")
    const selectedFrame = frames.find(frame => frame.id === frameId);
    console.log("selectedFrame", selectedFrame);
    if (selectedFrame) {
      try {
        setIsGeneratingHtml(true);
        console.log("fetchDiagramToHtml")
        const result = await fetchDiagramToHtml(selectedFrame, promptMessages);
        console.log("result", result);
        setShowAiResponse(true);
  
        if (result && result.html && result.html.content && result.html.content[0]) {
          setCurrentHtml(result.html.content[0].text);
        }
      } catch (error) {
        console.error("Error generating HTML:", error);
      } finally {
        setIsGeneratingHtml(false);
      }
    }
  }, [frames, fetchDiagramToHtml, setShowAiResponse, setIsGeneratingHtml, setCurrentHtml, promptMessages]);

  const memoizedFrames = useMemo(() => frames.map((frame, index) => (
    <Frame
      key={frame.id}
      frame={frame}
      index={index}
      featureId={featureId}
      featureDiagramId={featureDiagramId}
      onClick={() => handleFrameClick(frame)}
      isSelected={selectedFrame?.id === frame.id}
      onGenerateHtml={() => handleGenerateHTML(frame.id)}
      isGeneratingHtml={isGeneratingHtml}
    />
  )), [frames, featureId, handleFrameClick, selectedFrame, handleGenerateHTML, isGeneratingHtml]);

  return (
    <Box sx={{ position: 'relative', p: 2 }}>
      {showWarning && (
        <Alert severity="warning" sx={{ mt: 2, mb: 2 }}>
          Please select a frame (or create a new one by using cmd+f and dragging over the elements you want to frame) first.
        </Alert>
      )}

      <FramesGuide />

      <Paper sx={{ p: 3, mb: 3 }}>
        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={createNewFrame}
            startIcon={<AddIcon />}
          >
            New Frame
          </Button>
          <Button 
            variant="outlined" 
            color="secondary" 
            onClick={() => dispatch(setIsManageFramesModalOpen(true))}
            startIcon={<SettingsIcon />}
          >
            Manage Frames
          </Button>
        </Box>

        <Box sx={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="frames" direction="horizontal">
              {(provided) => (
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  display="flex"
                  sx={{ minWidth: 'min-content' }}
                >
                  {memoizedFrames}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Paper>

      <ManageFramesModal
        isOpen={isManageFramesModalOpen}
        excalidrawAPI={excalidrawAPI}
        featureId={featureId}
        featureDiagramId={featureDiagramId}
        onClose={() => dispatch(setIsManageFramesModalOpen(false))}
        frames={persistedFrames}
        deleteFrame={deleteFrame}
        updateFrame={updateFrame}
        updateFramePosition={updateFramePosition}
      />

      {showFrameForm && (
        <Paper sx={{ p: 3, mb: 3 }}>
          <FrameForm
            frameForm={frameForm}
            isSubmitting={isSubmitting}
            submitError={submitError}
            featureId={featureId}
            onSubmit={handleFrameFormSubmit}
            onChange={handleFrameFormChange}
            onCancel={() => setShowFrameForm(false)}
            snapshottedFrame={snapshottedFrame}
          />
        </Paper>
      )}

      {selectedFrame && (
        <Paper sx={{ p: 3, mb: 3 }}>
          <GeneratedHtml 
            html={currentHtml || (selectedFrame.frame_htmls && selectedFrame.frame_htmls.length > 0 
              ? selectedFrame.frame_htmls[0] 
                : sanitizedHtml || ''
              )}
              onPromptSubmit={(prompt) => handlePromptSubmit(prompt, selectedFrame.id)}
              isGeneratingHtml={isGeneratingHtml}
              setIsGeneratingHtml={setIsGeneratingHtml}
          />
        </Paper>
      )}
    </Box>
  );
};

export default React.memo(FramesTab);