// app/javascript/components/Excalidraw/components/ManageFrames/Frame.tsx
import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import {
  Typography,
  CardContent,
  IconButton,
  Tooltip,
  TextField,
  Box,
  useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CodeIcon from '@mui/icons-material/Code';
import EditIcon from '@mui/icons-material/Edit';
import { PersistedFrame } from '../../types/frameTypes';
import { StyledCard, StyledCardMedia, Toolbar } from './styles';
import { updateFrame, deleteFrame } from '../../stores/slices/framesSlice';

interface FrameProps {
  frame: PersistedFrame;
  index: number;
  featureId: string;
  featureDiagramId: string;
  onClick: () => void;
  onGenerateHtml: (frameId: number) => Promise<void>;
  isSelected: boolean;
  isGeneratingHtml: boolean;
}

const Frame: React.FC<FrameProps> = React.memo(({
  frame,
  index,
  featureId,
  featureDiagramId,
  onClick,
  onGenerateHtml,
  isSelected,
  isGeneratingHtml,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [editingName, setEditingName] = useState<string>(frame.name);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleDoubleClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setIsEditing(true);
  }, []);

  const handleNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEditingName(event.target.value);
  }, []);

  const handleNameSubmit = useCallback(async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      try {
        await dispatch(updateFrame({
          featureDiagramId,
          frameId: frame.id,
          updateData: { name: editingName }
        })).unwrap();
        setIsEditing(false);
      } catch (error) {
        console.error("Error updating frame:", error);
      }
    }
  }, [editingName, frame.id, featureDiagramId, dispatch]);

  const handleGenerateHTML = useCallback(async (e: React.MouseEvent) => {
    e.stopPropagation();
    await onGenerateHtml(frame.id);
  }, [frame.id, onGenerateHtml]);

  const handleDeleteFrame = useCallback(async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await dispatch(deleteFrame({ featureDiagramId, frameId: frame.id })).unwrap();
    } catch (error) {
      console.error("Error deleting frame:", error);
    }
  }, [frame.id, featureDiagramId, dispatch]);

  const cardSx = useMemo(() => ({
    transition: 'box-shadow 0.3s ease-in-out',
    boxShadow: isSelected
      ? `0 0 0 3px ${theme.palette.secondary.main}, 0 4px 6px rgba(0, 0, 0, 0.1)`
      : '0 4px 6px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      boxShadow: isSelected
        ? `0 0 0 3px ${theme.palette.secondary.main}, 0 6px 10px rgba(0, 0, 0, 0.2)`
        : '0 6px 10px rgba(0, 0, 0, 0.2)',
    },
  }), [isSelected, theme.palette.secondary.main]);

  return (
    <Draggable draggableId={frame.id.toString()} index={index}>
      {(provided) => (
        <StyledCard
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={onClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          sx={cardSx}
        >
          <Box position="relative">
            <StyledCardMedia
              image={frame.imageUrl}
              title={frame.name}
              sx={{
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                height: '200px',
              }}
            />
            <Toolbar isVisible={isHovered}>
              <Tooltip title="Delete">
                <IconButton
                  onClick={handleDeleteFrame}
                  color="error"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="View">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(frame.imageUrl, '_blank');
                  }}
                  color="primary"
                  size="small"
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
              {/* Generate HTML Button */}
              <Tooltip title="Generate HTML">
                <IconButton
                  onClick={handleGenerateHTML}
                  color="primary"
                  size="small"
                  disabled={isGeneratingHtml}
                >
                  <CodeIcon />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </Box>
          <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
            {isEditing ? (
              <TextField
                value={editingName}
                onChange={handleNameChange}
                onKeyPress={handleNameSubmit}
                onBlur={() => setIsEditing(false)}
                autoFocus
                fullWidth
                variant="standard"
                onClick={(e) => e.stopPropagation()}
                inputProps={{ style: { fontSize: '0.9rem' } }}
              />
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  cursor: 'pointer',
                  p: 0.5,
                  borderRadius: 1,
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
                onClick={handleDoubleClick}
              >
                <Typography
                  variant="subtitle2"
                  color="text.primary"
                  noWrap
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '0.9rem',
                    flexGrow: 1,
                  }}
                >
                  {frame.name}
                </Typography>
                <Tooltip title="Double-click to edit">
                  <EditIcon fontSize="small" color="action" sx={{ ml: 1 }} />
                </Tooltip>
              </Box>
            )}
          </CardContent>
        </StyledCard>
      )}
    </Draggable>
  );
});

export default React.memo(Frame);