import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "button"]

  connect() {
    this.isExpanded = false
    this.contentTarget.style.maxHeight = "0px"
    this.contentTarget.style.overflow = "hidden"
  }

  toggle() {
    this.isExpanded = !this.isExpanded
    
    if (this.isExpanded) {
      this.expand()
    } else {
      this.collapse()
    }
  }

  expand() {
    this.contentTarget.style.maxHeight = `${this.contentTarget.scrollHeight}px`
    this.contentTarget.classList.add("border")
    this.buttonTarget.textContent = "Show Less"
  }

  collapse() {
    this.contentTarget.style.maxHeight = "0px"
    this.contentTarget.classList.remove("border")
    this.buttonTarget.textContent = "Show More"
  }
}