// app/javascript/controllers/features/feature_tickets_controller.js
import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["expandableContent", "card", "ticketList"]
  static values = {
    featureId: String
  }

  connect() {
    // console.log("FeatureTicketsController connected")
    this.expanded = false
    if (this.hasExpandableContentTarget) {
      this.expandableContentTarget.style.maxHeight = "0"
      this.expandableContentTarget.style.overflow = "hidden"
    }
    this.initializeSortable()
  }

  initializeSortable() {
    if (this.hasTicketListTarget) {
      new Sortable(this.ticketListTarget, {
        animation: 150,
        onEnd: this.updatePositions.bind(this)
      })
    }
  }

  updatePositions(event) {
    const tickets = Array.from(this.ticketListTarget.children)
    const updatedPositions = tickets.map((ticket, index) => ({
      id: ticket.dataset.ticketId,
      position: index + 1
    }))

    fetch(`/tickets/update_positions`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ positions: updatedPositions })
    })
    .then(response => response.json())
    .then(data => {
      console.log('Positions updated successfully', data)
    })
    .catch(error => {
      console.error('Error updating positions:', error)
    })
  }

  toggleExpand(event) {
    this.expanded = !this.expanded

    if (this.expanded) {
      this.cardTarget.classList.add("min-h-[8rem]")
      this.expandableContentTarget.classList.remove("hidden")
      this.expandableContentTarget.style.maxHeight = "24rem" // max-h-24 is equivalent to 6rem
    } else {
      this.cardTarget.classList.remove("min-h-[8rem]")
      this.expandableContentTarget.style.maxHeight = "0"
    }

    // Rotate the chevron icon
    event.currentTarget.querySelector('svg').style.transform = this.expanded ? 'rotate(180deg)' : 'rotate(0)'
  }

  createTicket(event) {
    event.preventDefault()
    const url = `/features/${this.featureIdValue}/create_ticket`
    
    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json',
        'Accept': 'text/vnd.turbo-stream.html'
      },
      body: JSON.stringify({
        ticket: {
          ticketable_id: this.featureIdValue,
          ticketable_type: "Feature"
        }
      })
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
    })
    .catch(error => console.error('Error:', error))
  }
}