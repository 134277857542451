// app/javascript/controllers/user_configurable_controller.js

/**
 * UserConfigurable Controller
 *
 * This Stimulus controller manages user-specific configurations for UI elements,
 * particularly focusing on collapsible sections.
 *
 * Targets:
 * - toggleable: The element that will be toggled (shown/hidden)
 * - button: The button that triggers the toggle action
 *
 * Values:
 * - url: The endpoint URL for updating the user configuration
 * - configKey: The key in the configuration object (e.g., "feature_group")
 * - configProperty: The specific property to be updated (e.g., "is_collapsed")
 *
 * Usage:
 * <div data-controller="user-configurable"
 *      data-user-configurable-url-value="/feature_groups/1/update_config"
 *      data-user-configurable-config-key-value="feature_group"
 *      data-user-configurable-config-property-value="is_collapsed">
 *   <button data-user-configurable-target="button"
 *           data-action="click->user-configurable#toggle">
 *     Toggle
 *   </button>
 *   <div data-user-configurable-target="toggleable"
 *        class="toggle-closed">
 *     Content to be toggled
 *   </div>
 * </div>
 *
 * Note: Ensure that your server-side code can handle the nested configuration structure
 * sent in the PATCH request.
 */
// Todo, setup a the UserConfig routes as a concern
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggleable", "button"]
  static values = {
    url: String,
    configKey: String,
    configProperty: String
  }

  connect() {
    this.updateButtonRotation()
  }

  toggle(event) {
    event.preventDefault()
    const currentValue = this.toggleableTarget.classList.contains('toggle-closed')
    this.updateConfig(!currentValue)
  }

  updateConfig(value) {
    const body = {
      user_configurable: {
        config: {
          [this.configKeyValue]: {
            [this.configPropertyValue]: value
          }
        }
      }
    }

    fetch(this.urlValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        this.toggleableTarget.classList.toggle('toggle-closed', value)
        this.toggleableTarget.classList.toggle('toggle-open', !value)
        this.updateButtonRotation()
      }
    })
    .catch(error => console.error('Error:', error))
  }

  updateButtonRotation() {
    const isCollapsed = this.toggleableTarget.classList.contains('toggle-closed')
    this.buttonTarget.querySelector('svg').style.transform = isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)'
  }
}