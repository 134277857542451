// app/javascript/components/Excalidraw/stores/selectors/framesSelector.ts

import { RootState } from '../rootReducer';
import { createSelector } from '@reduxjs/toolkit';

export const selectFrames = (state: RootState) => state.frames.frames;
export const selectSelectedFrameId = (state: RootState) => state.frames.selectedFrameId;
export const selectIsManageFramesModalOpen = (state: RootState) => state.frames.isManageFramesModalOpen;
export const selectFramesLoading = (state: RootState) => state.frames.loading;
export const selectFramesError = (state: RootState) => state.frames.error;

export const selectSelectedFrame = createSelector(
  [selectFrames, selectSelectedFrameId],
  (frames, selectedFrameId) => frames.find(frame => frame.id === selectedFrameId) || null
);