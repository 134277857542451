// app/javascript/react/components/FramesTab/FramesGuide.tsx
import React, { useState } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Collapse,
  IconButton,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const InfoBox = styled(Paper)(({ theme }) => ({
  overflow: 'hidden',
  marginBottom: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadius * 1.5,
  boxShadow: theme.shadows[2],
  transition: theme.transitions.create(['box-shadow']),
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const InfoBoxHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1.5, 2.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  transition: theme.transitions.create(['background-color']),
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const InfoBoxContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.5),
  backgroundColor: theme.palette.background.default,
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  marginBottom: theme.spacing(0.5), // Reduced from 1 to 0.5
  '&:last-child': {
    marginBottom: 0,
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 36,
  color: theme.palette.primary.main,
}));

const FramesGuide: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme();

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <InfoBox>
      <InfoBoxHeader onClick={toggleExpand}>
        <Box display="flex" alignItems="center">
          <InfoIcon sx={{ mr: 1.5, fontSize: 24 }} />
          <Typography variant="subtitle1">Frames Guide</Typography>
        </Box>
        <IconButton
          onClick={toggleExpand}
          size="small"
          sx={{ color: 'inherit' }}
        >
          {isExpanded ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
        </IconButton>
      </InfoBoxHeader>
      <Collapse in={isExpanded}>
        <InfoBoxContent>
          <List dense disablePadding>
            {[
              { icon: <LooksOneIcon />, text: "Create an Excalidraw frame by using cmd+f and dragging over the elements you want to frame" },
              { icon: <LooksTwoIcon />, text: "Ensure the frame is selected then open Frames (AI) in the bottom toolbar" },
              { icon: <Looks3Icon />, text: 'Click "New Frame" button' },
              { icon: <Looks4Icon />, text: "Name your frame and give it a description" },
              { icon: <Looks5Icon />, text: "(AI) Hover over the frame and click the 'Generate HTML' icon button." },
            ].map((item, index) => (
              <StyledListItem key={index} disableGutters>
                <StyledListItemIcon>
                  {React.cloneElement(item.icon, { fontSize: "small" })}
                </StyledListItemIcon>
                <ListItemText 
                  primary={item.text} 
                  primaryTypographyProps={{ 
                    variant: 'caption',
                    sx: { 
                      color: theme.palette.text.secondary,
                      fontWeight: 400,
                    }
                  }} 
                />
              </StyledListItem>
            ))}
          </List>
        </InfoBoxContent>
      </Collapse>
    </InfoBox>
  );
};

export default React.memo(FramesGuide);