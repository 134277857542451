import React from "react";
import ReactDOM from "react-dom/client";
import ExcalidrawComponent from "./components/ExcalidrawComponent";
// import whyDidYouRender from '@welldone-software/why-did-you-render';

export function initializeExcalidraw() {
  console.log("Initializing Excalidraw");
  const excalidrawDiv = document.getElementById("excalidraw");
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  // whyDidYouRender(React, {
  //   trackAllPureComponents: true,
  // });

  if (excalidrawDiv) {
    console.log('Excalidraw div found, rendering component');
    const featureId = excalidrawDiv.getAttribute("data-feature-id");
    const featureUrl = excalidrawDiv.getAttribute("data-feature-path");
    const diagramId = excalidrawDiv.getAttribute("data-diagram-id");

    const root = ReactDOM.createRoot(excalidrawDiv);
    root.render(
      <div style={{ width: '100%', height: '100%', minHeight: '800px' }}>
        <ExcalidrawComponent featureId={featureId} featurePath={featureUrl} csrfToken={csrfToken} diagramId={diagramId} />
      </div>
    );
  } else {
    // console.error('Excalidraw div not found');
  }
}

// Keep the DOMContentLoaded event for initial page load
document.addEventListener("DOMContentLoaded", initializeExcalidraw);
