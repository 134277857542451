// app/javascript/controllers/ai_autocomplete_controller.js

/**
 * AI Autocomplete Controller
 * 
 * This Stimulus controller provides AI-powered autocomplete suggestions for text inputs.
 * It can be used for various purposes such as product descriptions, requirement objectives, etc.
 * 
 * Quick Start Guide:
 * 1. Include this controller in your Stimulus setup.
 * 2. Add the controller to your HTML: data-controller="ai-autocomplete"
 * 3. Set up the required targets in your HTML.
 * 4. Customize the controller as needed for your specific use case.
 * 
 * Required Targets:
 * - input: The main text input or textarea where user types
 *   <textarea data-ai-autocomplete-target="input"></textarea>
 * 
 * - suggestions: A container where suggestions will be displayed
 *   <div data-ai-autocomplete-target="suggestions"></div>
 * 
 * - productName: An input field for the product name (or equivalent)
 *   <input data-ai-autocomplete-target="productName">
 * 
 * Optional Targets:
 * - loading: A loading indicator element
 *   <div data-ai-autocomplete-target="loading" class="hidden">Loading...</div>
 * 
 * - toggle: A toggle button for enabling/disabling AI suggestions
 *   <button data-ai-autocomplete-target="toggle">Toggle AI</button>
 * 
 * - toggleButton: The inner part of the toggle button (for styling)
 *   <span data-ai-autocomplete-target="toggleButton"></span>
 * 
 * - promptInstructions: An input for additional AI instructions
 *   <input data-ai-autocomplete-target="promptInstructions">
 * 
 * - fetchButton: A button to manually fetch complete suggestions
 *   <button data-ai-autocomplete-target="fetchButton">Get Suggestions</button>
 * 
 * - aiAssistant: An input to specify the AI assistant ID
 *   <input data-ai-autocomplete-target="aiAssistant">
 * 
 * - errorMessageField: An element to display error messages
 *   <p data-ai-autocomplete-target="errorMessageField"></p>
 * 
 * Extending Functionality:
 * To adapt this controller for different use cases (e.g., requirement objectives instead of product names):
 * 1. Rename methods like `updateProductName` to `updateRequirementObjective`
 * 2. Update the corresponding target names in the HTML and in this controller
 * 3. Modify the `fetchSuggestions` and `fetchCompleteSuggestions` methods to include relevant context
 * 4. Adjust the custom prompts in these methods to fit your specific use case
 * 
 * Example of extending for requirement objectives:
 * - Rename `productName` target to `requirementObjective`
 * - Update `updateProductName` method to `updateRequirementObjective`
 * - Modify prompts to focus on requirement objectives instead of product descriptions
 */

import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"
export default class extends Controller {
  static targets = ["input", "suggestions", "loading", "productName", "toggle", "toggleButton", "promptInstructions", "fetchButton", "aiAssistant", "errorMessageField"]
  static values = {
    isFetching: { type: Boolean, default: false }
  }

  connect() {
    console.log("🚀 Connected to AI Autocomplete Controller 🚀")
    this.debounceTimer = null
    this.aiEnabled = false
    this.suggestionType = 'paragraph' // Default to paragraph suggestions
    this.aiAssistantId = this.aiAssistantTarget.value

    super.connect()
    this.channel = consumer.subscriptions.create("AiAutocompleteChannel", {
      received: (data) => {
        this.displaySuggestions(data)
        this.hideLoading()
        this.isFetchingValue = false
        this.fetchButtonTarget.classList.remove('animate-pulse')
      }
    })

    document.addEventListener('click', this.handleOutsideClick.bind(this))
  }

  disconnect() {
    // Remove event listener when controller is disconnected
    document.removeEventListener('click', this.handleOutsideClick.bind(this))
  }

  updateAiAssistant() {
    this.aiAssistantId = this.aiAssistantTarget.value
  }

  handleOutsideClick(event) {
    if (!this.suggestionsTarget.contains(event.target) && !this.inputTarget.contains(event.target) && !this.fetchButtonTarget.contains(event.target)) {
      this.dismissSuggestions()
    }
  }

  dismissSuggestions() {
    this.suggestionsTarget.classList.add('hidden')
    this.suggestionsTarget.innerHTML = ''
  }

  toggleAI() {
    this.aiEnabled = !this.aiEnabled
    console.log("🚀 Toggling AI: ", this.aiEnabled)
    this.toggleTarget.setAttribute("aria-checked", this.aiEnabled)
    if (this.aiEnabled) {
      this.toggleTarget.classList.remove("bg-gray-200")
      this.toggleTarget.classList.add("bg-green-400")
      this.toggleButtonTarget.classList.remove("translate-x-0")
      this.toggleButtonTarget.classList.add("translate-x-5")
      this.suggestionsTarget.classList.remove("hidden") // Show suggestions
    } else {
      this.toggleTarget.classList.remove("bg-green-400")
      this.toggleTarget.classList.add("bg-gray-200")
      this.toggleButtonTarget.classList.remove("translate-x-5")
      this.toggleButtonTarget.classList.add("translate-x-0")
      this.suggestionsTarget.classList.add("hidden") // Hide suggestions
      this.suggestionsTarget.innerHTML = '' // Clear suggestions
      this.loadingTarget.classList.add("hidden") // Hide loading indicator
    }
  }

  updateProductName() {
    // This method will be called whenever the product name input changes
    // You can use this to update any other elements that need the product name
  }

  suggest() {
    if (!this.aiEnabled) return
    clearTimeout(this.debounceTimer)
    this.debounceTimer = setTimeout(() => {
      const content = this.inputTarget.value
      const cursorPosition = this.inputTarget.selectionStart
      const currentParagraph = this.getCurrentParagraph(content, cursorPosition)
      
      // Check if the current paragraph has at least 3 words
      if (currentParagraph.trim().split(/\s+/).length >= 3) {
        this.fetchSuggestions()
      } else {
        this.dismissSuggestions()
      }
    }, 1150)
  }

  async fetchSuggestions() {
    if (!this.aiEnabled) return
    if (this.isFetchingValue) return

    this.suggestionType = 'paragraph'
    this.showLoading()
    
    const content = this.inputTarget.value
    const cursorPosition = this.inputTarget.selectionStart
    const { currentParagraph, previousParagraphs } = this.getParagraphsContext(content, cursorPosition)
    
    const model = this.inputTarget.dataset.model
    const field = this.inputTarget.dataset.field
    const productName = this.productNameTarget.value
    const promptInstructions = this.hasPromptInstructionsTarget ? this.promptInstructionsTarget.value : ''
  
    const customPrompt = `
      You are assisting in writing a product description for "${productName}". 
      The description so far is:
  
      ${previousParagraphs}
  
      The current paragraph being worked on, for which you are providing autocomplete suggestions for is:
  
      ${currentParagraph}
  
      Please provide 2 suggestions to complete or improve the sentence the user is typing. Each suggestion should:
      1. Be coherent with the previous content
      2. Maintain the style and tone of the existing description
      3. Adhere to the assistant's instructions
  
      Format each suggestion as "[Your suggested text here]"
    `
  
    this.isFetchingValue = true
    const response = await fetch('/ai_autocomplete/suggest', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ 
        content: currentParagraph,
        model, 
        field, 
        product_name: productName, 
        prompt_instructions: "Write only 1 to 2 sentences per suggestion, max.",
        custom_prompt: customPrompt,
        ai_assistant_id: this.aiAssistantId
      })
    })
  

    if (!response.ok) {
      console.error('Error fetching suggestions')
      this.hideLoading()
    }
  }

  async fetchCompleteSuggestions() {
    console.log("fetchCompleteSuggestions")
    if (this.isFetchingValue) {
      this.showMessage(this.errorMessageFieldTarget, "Please wait, suggestions are already being generated.", "bg-red-400", 6000);
      return
    }

    this.suggestionType = 'complete'
    this.showLoading()

    const content = this.inputTarget.value
    const model = this.inputTarget.dataset.model
    const field = this.inputTarget.dataset.field
    const productName = this.productNameTarget.value
    const promptInstructions = this.hasPromptInstructionsTarget ? this.promptInstructionsTarget.value : ''
    const customPrompt = this.inputTarget.dataset.customPrompt

    this.isFetchingValue = true
    this.fetchButtonTarget.classList.add('animate-pulse')
    const response = await fetch('/ai_autocomplete/suggest', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ 
        content, 
        model, 
        field, 
        product_name: productName, 
        prompt_instructions: promptInstructions,
        custom_prompt: customPrompt,
        ai_assistant_id: this.aiAssistantId
      })
    })

    const data = await response.json()
    console.log("data response: ", data)
    this.displaySuggestions(data)
    this.hideLoading()
  }

  displaySuggestions(data) {
    const { suggestions, error, error_details } = data
    
    if (error) {
      this.showMessage(this.errorMessageFieldTarget, error, "bg-red-400", 15000);
      console.error("Error: ", error, "Details: ", error_details)
      this.suggestionsTarget.innerHTML = ''
      this.suggestionsTarget.classList.add('hidden')
      return;
    }
    if (suggestions === undefined) {
      return;
    }

    if (suggestions.length === 0) {
      this.suggestionsTarget.innerHTML = '<div class="p-4 text-sm text-gray-500">No suggestions available.</div>'
    } else {
      this.suggestionsTarget.classList.remove('hidden')
      this.suggestionsTarget.innerHTML = suggestions.map(suggestion => 
        `<button class="suggestion-btn block w-full text-left px-4 py-2 hover:bg-gray-100 whitespace-pre-wrap" data-action="click->ai-autocomplete#selectSuggestion">${suggestion}</button>`
      ).join('')
    }
  }

  selectSuggestion(event) {
    const selectedText = event.target.textContent
    const currentContent = this.inputTarget.value
    
    if (this.suggestionType === 'complete') {
      // Replace the entire content
      this.inputTarget.value = selectedText
      const textLength = selectedText.length
      this.inputTarget.setSelectionRange(textLength, textLength)
    } else {
      // Replace only the current paragraph
      const cursorPosition = this.inputTarget.selectionStart
      const { start, end } = this.getParagraphBoundaries(currentContent, cursorPosition)
      
      const newContent = currentContent.substring(0, start) + selectedText + currentContent.substring(end)
      this.inputTarget.value = newContent
      
      const newCursorPosition = start + selectedText.length
      this.inputTarget.setSelectionRange(newCursorPosition, newCursorPosition)
    }
    
    // Clear the suggestions
    this.suggestionsTarget.innerHTML = ''
    this.suggestionsTarget.classList.add('hidden')
    this.inputTarget.focus()
  }

  getCurrentParagraph(content, cursorPosition) {
    const { start, end } = this.getParagraphBoundaries(content, cursorPosition)
    return content.substring(start, end).trim()
  }

  getParagraphBoundaries(content, cursorPosition) {
    let start = cursorPosition
    let end = cursorPosition

    // Find the start of the paragraph
    while (start > 0 && content[start - 1] !== '\n') {
      start--
    }

    // Find the end of the paragraph
    while (end < content.length && content[end] !== '\n') {
      end++
    }

    return { start, end }
  }

  getParagraphsContext(content, cursorPosition) {
    const paragraphs = content.split('\n\n')
    let currentParagraphIndex = 0
    let currentPosition = 0

    for (let i = 0; i < paragraphs.length; i++) {
      currentPosition += paragraphs[i].length + 2 // +2 for the '\n\n'
      if (currentPosition > cursorPosition) {
        currentParagraphIndex = i
        break
      }
    }

    const currentParagraph = paragraphs[currentParagraphIndex]
    const previousParagraphs = paragraphs.slice(0, currentParagraphIndex).join('\n\n')

    return { currentParagraph, previousParagraphs }
  }

  showLoading() {
    this.suggestionsTarget.innerHTML = `
      <div class="p-4 text-sm text-gray-500">
        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-500 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        Generating AI suggestions...
      </div>
    `
    this.suggestionsTarget.classList.remove('hidden')
  }

  hideLoading() {
    // This method is now empty because we're replacing the loading content with suggestions
  }

  showMessage(messageField, message, styling, duration) {
    messageField.textContent = message
    messageField.classList.remove('hidden')
    messageField.classList.add(styling)

    setTimeout(() => {
      messageField.classList.add('hidden')
      messageField.classList.remove(styling)
    }, duration)
  }
}