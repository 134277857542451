import React from 'react';
import { Typography, Box, Divider, List, ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const FrameAssociationTab: React.FC = () => {
  const theme = useTheme();

  return (
    <Box mt={3}>
      <Typography variant="h6" color="primary" fontWeight="bold" sx={{ display: 'flex', alignItems: 'center' }}>
        <AccountTreeIcon sx={{ mr: 1 }} />
        Coming Soon: Frame Association
      </Typography>
      <Divider sx={{ my: 1 }} />
      <List dense>
        <ListItem>
          <ListItemIcon>
            <ArrowUpwardIcon color="action" />
          </ListItemIcon>
          <ListItemText
            primary="Parent Frames"
            secondary="Frame 1, Frame 2"
            primaryTypographyProps={{ color: 'textPrimary' }}
            secondaryTypographyProps={{ color: 'textSecondary' }}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <ArrowDownwardIcon color="action" />
          </ListItemIcon>
          <ListItemText
            primary="Child Frames"
            secondary="Frame 4, Frame 5, Frame 6"
            primaryTypographyProps={{ color: 'textPrimary' }}
            secondaryTypographyProps={{ color: 'textSecondary' }}
          />
        </ListItem>
      </List>
      <Typography variant="body2" color="textSecondary" sx={{ mt: 1, fontStyle: 'italic' }}>
        In the future, you'll be able to create hierarchical relationships between frames,
        allowing for more complex and organized project structures.
      </Typography>
    </Box>
  );
};

export default FrameAssociationTab;