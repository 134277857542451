import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]

  connect() {
    console.log("FeatureTickets::ModalController connected")
  }

  open(event) {
    event.preventDefault()
    const modalId = event.currentTarget.dataset.modalContent
    const modal = document.getElementById(modalId)
    if (modal) {
      modal.classList.remove('hidden')
    }
  }

  close(event) {
    event.preventDefault()
    const modal = event.target.closest('.modal')
    if (modal) {
      modal.classList.add('hidden')
    }
  }

  closeBackground(event) {
    if (event.target === event.currentTarget) {
      this.close(event)
    }
  }
}