// Usage See below.
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'status']

  connect() {
    this.timeout = null
    this.duration = this.data.get('duration') || 1000
    console.log("this has worked")
  }

  save() {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.statusTarget.textContent = 'Saving...'
      this.formTarget.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      )
    }, this.duration)
  }

  success() {
    this.setStatus('Saved!')
  }

  error() {
    this.setStatus('Unable to save!')
  }

  setStatus(message) {
    this.statusTarget.textContent = message

    this.timeout = setTimeout(() => {
      this.statusTarget.textContent = ''
    }, 2000)
  }
}

// <%= form_with(model: post, data: { controller: "new-product-wizard", target: "new-product-wizard.form", action: "ajax:success->new-product-wizard#success ajax:error->new-product-wizard#error" }) do | form | %>
//   <div class="form-group">
//     <%= form.label :title %>
//     <%= form.text_field :title, class: 'form-control', data: { action: "keyup->new-product-wizard#save" } %>
//   </div>

//   <div class="form-group">
//     <%= form.label :body %>
//     <%= form.text_area :body, class: 'form-control', data: { action: "keyup->new-product-wizard#save" } %>
//   </div>

//   <div class="form-group">
//     <%= form.submit "Save and Publish", class: 'btn btn-primary' %>

//     <span class="text-muted" data-new-product-wizard-target="status"></span>
//   </div>
// <% end %>
