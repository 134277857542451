import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auto-submit"
export default class extends Controller {
    static targets = ["form"];

    connect() {
        this.element.addEventListener("change", this.submitForm.bind(this));
        console.log("Autosave is online.")
    }

    submitForm() {
        this.formTarget.requestSubmit();
    }
}
