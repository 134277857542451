import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static get targets() {
    return ["myInput"]
  }

  static values = {
    accountId: Number,
    taggableType: String,
    taggableId: String,
    existingTags: Array
  }

  connect() {
    this.initTomSelect()
  }

  initTomSelect() {
    let self = this
    this.tomSelect = new TomSelect(this.myInputTarget, {
      maxItems: 25,
      valueField: 'id',
      labelField: 'name',
      items: self.existingTagsValue.map(tag => tag.id),
      plugins: {
        remove_button: {
          title: 'Remove tag',
        }
      },
      searchField: ['name'],
      load: function (query, callback) {
        if (!query.length) return callback()
        fetch(`/tags?query=${encodeURIComponent(query)}`)
          .then(response => response.json())
          .then(tags => callback(tags))
          .catch(error => callback())
      },
      create: function (input, callback) {
        fetch('/tags', {
          method: 'POST',
          body: JSON.stringify({ name: input, account_id: self.accountIdValue }), // use the account id
          headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content') }
        })
          .then(response => response.json())
          .then(tag => {
            try {
              this.addOption({ value: tag.id, text: tag.name })
              this.addItem(tag.id)
            } catch (error) {
              console.error('Error adding option or item:', error)
            }
            // Call the callback at the end
            callback({ id: tag.id, name: tag.name })
          })
          .catch(error => callback())
      },
      onItemAdd: function (value, $item) {
        fetch('/taggings', {
          method: 'POST',
          body: JSON.stringify({ tag_id: value, taggable_type: self.taggableTypeValue, taggable_id: self.taggableIdValue, account_id: self.accountIdValue }),
          headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content') }
        })
      },
      onItemRemove: function (value) {
        fetch(`/taggings/${value}`, {
          method: 'DELETE',
          body: JSON.stringify({ id: value }),
          headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content') }
        })
      },
    })
  }
}
