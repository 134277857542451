import { ApplicationController, useClickOutside, useDebounce } from "stimulus-use"
import { useHotkeys } from "stimulus-use/hotkeys"
import { post } from "@rails/request.js"

// Connects to data-controller="requirement-autocomplete"
export default class extends ApplicationController {
  static targets = ["input", "suggestionsPanel"]

  static debounces = ["autocomplete"]
  static values = {
    wait: { type: Number, default: 1000 }
  }

  connect() {
    this.openSuggestionsPanels = []

    useClickOutside(this)
    useDebounce(this, { wait: this.waitValue })

    this.setupHotkeys()

    document.addEventListener("turbo:before-cache", this.beforeCache)
  }

  disconnect() {
    document.removeEventListener("turbo:before-cache", this.beforeCache)

    this.removeHotkeys()
  }

  clickOutside(event) {
    const clickEl = event.target

    // if inside a form, do nothing
    if (clickEl.form) return

    this.closeAll()
  }

  open(evt) {
    const inputIndex = this.inputTargets.indexOf(evt.target)
    const suggestionsPanel = this.suggestionsPanelTargets[inputIndex]
    this.openSuggestionsPanel(suggestionsPanel)
  }

  closeAll() {
    console.log("Closing all suggestionsPanels")
    this.openSuggestionsPanels.forEach(suggestionsPanel => this.closePanel(suggestionsPanel))
  }

  openSuggestionsPanel(suggestionsPanel) {
    suggestionsPanel.classList.remove("hidden")
    this.openSuggestionsPanels.push(suggestionsPanel)
  }

  closePanel(suggestionsPanel) {
    suggestionsPanel.classList.add("hidden")
    this.openSuggestionsPanels = this.openSuggestionsPanels.filter(p => p !== suggestionsPanel)

    this.refreshRequirementsList(suggestionsPanel)
  }

  refreshRequirementsList(suggestionsPanel) {
    // Find parent node with class requirement-list
    const requirementList = suggestionsPanel.closest(".requirement-list")
    if (!requirementList) return

    // Ask server to refresh the feature's requirements lists as it may have new requirements
    post(`/requirements/refresh`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({
        feature_id: requirementList.dataset.feature,
        product_user_role_id: requirementList.dataset.productUserRole
      })
    })
  }

  async autocomplete(evt) {
    const input = evt.target
    console.log(`Triggering autocomplete: "${input.value}"`)
    const queryString = input.form.action.split("?")[1]
    const params = new URLSearchParams(queryString)
    const userContent = input.value
    const body = {
      action_type: params.get("action_type"),
      feature_id: params.get("feature_id"),
      product_user_role_id: params.get("product_user_role_id"),
      user_content: userContent
    }
    await post("/requirement_autocompletes", {
      responseKind: "turbo-stream",
      method: "POST",
      body: JSON.stringify({ requirement_autocomplete: body })
    })

    if (userContent === "") {
      const inputIndex = this.inputTargets.indexOf(evt.target)
      const suggestionsPanel = this.suggestionsPanelTargets[inputIndex]
      this.refreshRequirementsList(suggestionsPanel)
      // TODO: use morphing so cursor stays in the same input
    }
  }


  // Ensures the menu is hidden before Turbo caches the page
  beforeCache = () => {
    this.openSuggestionsPanels = []
    this.suggestionsPanelTargets.forEach(suggestionsPanel => suggestionsPanel.classList.add("hidden"))
  }

  setupHotkeys() {
    useHotkeys(this, { "esc": [this.closeAll] })
    const self = this
    this.inputTargets.forEach(input => {
      input.addEventListener("keydown", function (event) {
        if (event.key === "Escape") {
          self.closeAll()
        }
      })
    })
  }

  removeHotkeys() {
    this.inputTargets.forEach(input => {
      input.removeEventListener("keydown", function (event) {
        if (event.key === "Escape") {
          self.closeAll()
        }
      })
    })
  }
}
