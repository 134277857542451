// app/javascript/components/Excalidraw/components/SlideOver/FramesTab/FrameForm.tsx
import React from 'react';
import { FrameFormData } from '../../../types/frameTypes';
import { UnpersistedFrame } from '../../../types/frameTypes';
import SnapshottedFrame from './SnapshottedFrame';
import {
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  useTheme,
  Divider
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

interface FrameFormProps {
  frameForm: FrameFormData;
  isSubmitting: boolean;
  submitError: string | null;
  onSubmit: (e: React.FormEvent) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onCancel: () => void;
  snapshottedFrame: UnpersistedFrame;
}

const FrameForm: React.FC<FrameFormProps> = ({
  frameForm,
  isSubmitting,
  submitError,
  onSubmit,
  onChange,
  onCancel,
  snapshottedFrame,
}) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={3}
      sx={{
        mt: 3,
        p: 4,
        borderRadius: '12px',
        background: theme.palette.background.paper,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography variant="h5" color="primary" fontWeight="bold" mb={3}>
        New Frame Details
      </Typography>
      <Divider sx={{ mb: 3 }} />
      {submitError && (
        <Typography color="error" mb={3}>
          {submitError}
        </Typography>
      )}
      <Box component="form" onSubmit={onSubmit}>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={4}>
          {/* Left element in row (Preview) */}
          <Box width={{ xs: '100%', md: '40%' }} mb={{ xs: 3, md: 0 }}>
            {snapshottedFrame && (
              <SnapshottedFrame frame={snapshottedFrame} />
            )}
          </Box>

          {/* Right element in row (Form) */}
          <Box width={{ xs: '100%', md: '60%' }} display="flex" flexDirection="column" gap={3}>
            <TextField
              fullWidth
              label="Name"
              id="name"
              name="name"
              value={frameForm.name}
              onChange={onChange}
              required
              variant="outlined"
              InputProps={{
                sx: { borderRadius: '8px' }
              }}
            />
            <TextField
              fullWidth
              label="Description (optional)"
              id="description"
              name="description"
              value={frameForm.description}
              onChange={onChange}
              multiline
              rows={4}
              variant="outlined"
              InputProps={{
                sx: { borderRadius: '8px' }
              }}
            />
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button
                onClick={onCancel}
                disabled={isSubmitting}
                startIcon={<CancelIcon />}
                variant="outlined"
                color="secondary"
                sx={{ mr: 2, borderRadius: '20px', px: 3 }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting}
                startIcon={<SaveIcon />}
                variant="contained"
                color="primary"
                sx={{ borderRadius: '20px', px: 3 }}
              >
                {isSubmitting ? 'Saving...' : 'Save Frame'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default React.memo(FrameForm);