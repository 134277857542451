// app/javascript/components/Excalidraw/components/GeneratedHtml.tsx

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Tabs, Tab, Divider, TextField, Button, CircularProgress } from '@mui/material';
import { selectSelectedFrame } from '../stores/selectors/framesSelector';
import CodeBlock from '../../common/CodeBlock';

interface GeneratedHtmlProps {
  onPromptSubmit: (prompt: { type: string; text: string }) => void;
  isGeneratingHtml: boolean;
  setIsGeneratingHtml: (isGeneratingHtml: boolean) => void;
}

const GeneratedHtml: React.FC<GeneratedHtmlProps> = ({ onPromptSubmit, isGeneratingHtml, setIsGeneratingHtml }) => {
  const selectedFrame = useSelector(selectSelectedFrame);
  const [tabValue, setTabValue] = useState(0);
  const [prompt, setPrompt] = useState('');
  const [currentHtml, setCurrentHtml] = useState('');

  useEffect(() => {
    if (selectedFrame && selectedFrame.frame_htmls && selectedFrame.frame_htmls.length > 0) {
      setCurrentHtml(selectedFrame.frame_htmls[0]);
    } else {
      setCurrentHtml('// Generated Code will appear here');
    }
  }, [selectedFrame]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handlePromptSubmit = async () => {
    setIsGeneratingHtml(true);
    const response = await onPromptSubmit({type: "text", text: prompt});
    setIsGeneratingHtml(false);
    setPrompt('');
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Generated HTML
      </Typography>
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          label="Enter prompt"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button 
          variant="contained" 
          onClick={handlePromptSubmit} 
          disabled={isGeneratingHtml}
        >
          Submit Prompt
        </Button>
      </Box>
      <Tabs value={tabValue} onChange={handleTabChange} sx={{ mb: 2 }}>
        <Tab label="Code" />
        <Tab label="Preview" />
      </Tabs>
      <Divider />
      {tabValue === 0 && (
        <Box position="relative" minHeight="200px">
          {/* temp fix for newlines, haven't tracked down why they're being added */}
          <CodeBlock code={currentHtml.replace(/\n\s*\n/g, "\n")} language="html" />
          {isGeneratingHtml && (
            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      )}
      {tabValue === 1 && (
        <Box position="relative" minHeight="200px">
          <Box 
            sx={{ minHeight: '200px', overflow: 'auto' }}
            dangerouslySetInnerHTML={{ __html: currentHtml }} 
          />
          {isGeneratingHtml && (
            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default GeneratedHtml;