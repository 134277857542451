// controllers/preview_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "preview"];

  connect() {
    console.log("This is loading Preview Controller")
    this.updatePreview();
  }

  updatePreview() {
    const code = this.inputTarget.value;
    if (this.isValid(code)) {
      const iframeContent = `
      <html>
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <script src="https://cdn.tailwindcss.com"></script>
        </head>
        <body class="antialiased p-5 bg-white">
          ${code}
        </body>
      </html>
    `;

      const iframeDocument = this.previewTarget.contentDocument;
      iframeDocument.open();
      iframeDocument.write(iframeContent);
      iframeDocument.close();
    }
  }


  isValid(code) {
    // This is a naive way of checking. 
    // You might want to expand on this for more security and accuracy.
    const forbiddenKeywords = ["<script>", "</script>"];
    for (let keyword of forbiddenKeywords) {
      if (code.includes(keyword)) return false;
    }
    return true;
  }
}
