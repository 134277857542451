import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu"]

  connect() {
    console.log(this.menuTarget)
    console.log("Hello Mendle you stunning man!");
  }

  toggle() {
    this.menuTarget.classList.toggle("hidden")
    if (this.menuTarget.classList.contains("-translate-x-full")) {
      this.menuTarget.classList.remove("-translate-x-full")
      this.menuTarget.classList.add("translate-x-full")
    } else {
      this.menuTarget.classList.remove("translate-x-full")
      this.menuTarget.classList.add("-translate-x-full")
    }
  }


  hide() {

  }

  show() {
  }

}
