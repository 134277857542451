// app/javascript/components/Excalidraw/components/SlideOver.tsx
import React, { useState } from 'react';
import {
    Drawer,
    Box,
    Button,
    Tabs,
    Tab,
    useTheme
} from '@mui/material';
import GeneratingHtmlTab from './SlideOver/GeneratingHtmlTab';
import FramesTab from './SlideOver/FramesTab';
import { AnthropicResponse } from '../types';
import { ExcalidrawImperativeAPI } from "@excalidraw/excalidraw/types/types";

interface SlideOverProps {
    isOpen: boolean;
    onClose: () => void;
    onGenerateHtml: (inputText: string) => Promise<AnthropicResponse>;
    excalidrawAPI: ExcalidrawImperativeAPI;
    featureId: string;
    featureDiagramId: string;
}

const SlideOver: React.FC<SlideOverProps> = ({ 
    isOpen, 
    onClose, 
    onGenerateHtml, 
    excalidrawAPI, 
    featureDiagramId, 
    featureId 
}) => {
    const [activeTab, setActiveTab] = useState(0);
    const theme = useTheme();

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    return (
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: '100%',
                    maxWidth: 900,
                }
            }}
        >
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="slide over tabs"
            >
                <Tab label={<span style={{ fontFamily: 'Roboto, sans-serif', fontSize: '1rem', fontWeight: 'bold', color: 'green' }}>Frames<span style={{ color: 'blue', fontSize: '0.55rem', marginLeft: '8px', verticalAlign: 'super', fontWeight: 'bold' }}>Beta</span></span>} />
                {/* <Tab label="Generate HTML" /> */}
            </Tabs>

            <Box sx={{ flexGrow: 1, overflow: 'auto', p: 3 }}>
                {activeTab === 0 ? (
                    <FramesTab 
                        excalidrawAPI={excalidrawAPI} 
                        featureDiagramId={featureDiagramId} 
                        featureId={featureId} 
                    />
                ) : (
                    <GeneratingHtmlTab 
                        onGenerateHtml={onGenerateHtml} 
                        excalidrawAPI={excalidrawAPI}
                        featureDiagramId={featureDiagramId}
                        featureId={featureId}
                    />
                )}
            </Box>

            <Box sx={{ p: 2, borderTop: 1, borderColor: 'divider' }}>
                <Button
                    variant="contained"
                    onClick={onClose}
                    fullWidth
                >
                    Close
                </Button>
            </Box>
        </Drawer>
    );
};

export default React.memo(SlideOver);