import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "icon"]

  connect() {
    this.isExpanded = false
  }

  toggle() {
    // Load the Turbo Frame content if it hasn't been loaded yet
    const turboFrame = this.contentTarget.querySelector("turbo-frame")
    if (turboFrame && !turboFrame.hasAttribute("complete")) {
      turboFrame.reload()
    }

    if (this.isExpanded) {
      this.collapse()
    } else {
      this.expand()
    }
    this.isExpanded = !this.isExpanded
  }

  expand() {
    this.contentTarget.style.maxHeight = `${this.contentTarget.scrollHeight}px`
    this.iconTarget.classList.add("rotate-180")
  }

  collapse() {
    this.contentTarget.style.maxHeight = "0"
    this.iconTarget.classList.remove("rotate-180")
  }
}