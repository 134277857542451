import React, { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PersistedFrame } from '../../types/frameTypes';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Paper,
  Divider,
  useTheme,
} from '@mui/material';
import Frame from './Frame';
import SelectedContent from './SelectedContent';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import useGenerateHTML from '../../hooks/useGenerateHTML';
import { 
  selectFrames, 
  selectSelectedFrame, 
  selectIsManageFramesModalOpen 
} from '../../stores/selectors/framesSelector';
import { 
  setSelectedFrameId, 
  setIsManageFramesModalOpen, 
  updateFrame,
  deleteFrame,
  updateFramePositions
} from '../../stores/slices/framesSlice';

interface ManageFramesModalProps {
  featureId: string;
  featureDiagramId: string;
  excalidrawAPI: any;
}

const ManageFramesModal: React.FC<ManageFramesModalProps> = ({
  featureId,
  featureDiagramId,
  excalidrawAPI,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const frames = useSelector(selectFrames);
  const selectedFrame = useSelector(selectSelectedFrame);
  const isOpen = useSelector(selectIsManageFramesModalOpen);

  const { fetchDiagramToHtml } = useGenerateHTML(excalidrawAPI, featureDiagramId, featureId);

  const sortedFrames = useMemo(() => {
    return [...frames].sort((a, b) => {
      if (a.position === 0) return -1;
      if (b.position === 0) return 1;
      return a.position - b.position;
    });
  }, [frames]);

  useEffect(() => {
    if (sortedFrames.length > 0 && !selectedFrame) {
      dispatch(setSelectedFrameId(sortedFrames[0].id));
    }
  }, [sortedFrames, selectedFrame, dispatch]);

  const onDragEnd = useCallback(async (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(sortedFrames);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedItems = items.map((item, index) => ({
      id: item.id,
      position: index === 0 ? 0 : index,
    }));

    dispatch(updateFramePositions({ featureDiagramId, frames: updatedItems }));
  }, [sortedFrames, featureDiagramId, dispatch]);

  const handleFrameClick = useCallback((frame: PersistedFrame) => {
    dispatch(setSelectedFrameId(frame.id));
  }, [dispatch]);

  const handleUpdateFrame = useCallback(async (frameId: number, updateData: Partial<PersistedFrame>) => {
    try {
      await dispatch(updateFrame({ featureDiagramId, frameId, updateData })).unwrap();
      return true;
    } catch (error) {
      console.error("Error updating frame:", error);
      return false;
    }
  }, [featureDiagramId, dispatch]);

  const handleDeleteFrame = useCallback(async (frameId: number) => {
    try {
      await dispatch(deleteFrame({ featureDiagramId, frameId })).unwrap();
    } catch (error) {
      console.error("Error deleting frame:", error);
    }
  }, [featureDiagramId, dispatch]);

  const handleGenerateHTML = useCallback(async (frameId: number) => {
    const frame = frames.find(f => f.id === frameId);

    if (frame) {
      try {
        const result = await fetchDiagramToHtml(frame, []);
  
        if (result && result.html && result.html.content && result.html.content[0]) {
          await handleUpdateFrame(frameId, { frame_htmls: [result.html.content[0].text] });
        }
      } catch (error) {
        console.error("Error generating HTML:", error);
      }
    }
  }, [frames, fetchDiagramToHtml, handleUpdateFrame]);

  const onClose = useCallback(() => {
    dispatch(setIsManageFramesModalOpen(false));
  }, [dispatch]);

  return (
    <Dialog 
      open={isOpen} 
      onClose={onClose} 
      maxWidth="lg" 
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: theme.shape.borderRadius,
          boxShadow: theme.shadows[10],
        }
      }}
    >
      <DialogTitle sx={{ bgcolor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>
        <Box display="flex" alignItems="center">
          <ViewQuiltIcon sx={{ mr: 1, fontSize: 28 }} />
          <Typography variant="h5">Manage Frames</Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ p: 3, bgcolor: theme.palette.background.default }}>
        <Paper 
          elevation={3}
          sx={{
            mt: 3, mb: 3, p: 3,
            borderTop: `4px solid ${theme.palette.secondary.main}`,
            borderRadius: theme.shape.borderRadius,
            background: `linear-gradient(145deg, ${theme.palette.background.paper} 0%, ${theme.palette.grey[100]} 100%)`,
          }}
        >
          <Typography variant="h6" color="secondary" fontWeight="bold" mb={2}>
            Frame List
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Box
            sx={{
              overflowX: 'auto',
              whiteSpace: 'nowrap',
              backgroundColor: 'rgba(0, 0, 0, 0.03)',
              borderRadius: theme.shape.borderRadius,
              padding: 2,
              '&::-webkit-scrollbar': {
                height: '8px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: theme.palette.background.paper,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.grey[400],
                borderRadius: '4px',
              },
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="frames" direction="horizontal">
                {(provided) => (
                  <Box
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    display="flex"
                    sx={{ minWidth: 'min-content' }}
                  >
                    {sortedFrames.map((frame, index) => (
                      <Frame
                        key={frame.id}
                        frame={frame}
                        index={index}
                        featureId={featureId}
                        featureDiagramId={featureDiagramId}
                        deleteFrame={handleDeleteFrame}
                        updateFrame={handleUpdateFrame}
                        onClick={() => handleFrameClick(frame)}
                        isSelected={selectedFrame?.id === frame.id}
                        onGenerateHtml={() => handleGenerateHTML(frame.id)}
                      />
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Paper>
        <SelectedContent 
          selectedFrame={selectedFrame} 
          updateFrame={handleUpdateFrame} 
          excalidrawAPI={excalidrawAPI}
          featureDiagramId={featureDiagramId}
          featureId={featureId}
        />
      </DialogContent>
      <DialogActions sx={{ p: 3, bgcolor: theme.palette.background.default }}>
        <Button 
          onClick={onClose} 
          color="primary" 
          variant="contained"
          sx={{
            borderRadius: '20px',
            boxShadow: theme.shadows[2],
            '&:hover': {
              boxShadow: theme.shadows[4],
            },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ManageFramesModal);