import { styled } from '@mui/material/styles';
import { Card, CardMedia, Box } from '@mui/material';

export const StyledCard = styled(Card)(({ theme }) => ({
  width: 200,
  margin: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  cursor: 'pointer',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

export const StyledCardMedia = styled(CardMedia)({
  height: 140,
  objectFit: 'cover',
});

export const Toolbar = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isVisible',
})<{ isVisible: boolean }>(({ theme, isVisible }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0.5),
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  opacity: isVisible ? 1 : 0,
  transition: 'opacity 0.2s ease-in-out',
  pointerEvents: isVisible ? 'auto' : 'none',
}));

export const FrameContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  overflowX: 'auto',
}));