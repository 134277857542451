// useFrameToHTML.ts

import { useState, useCallback } from 'react';
import { exportToBlob } from '@excalidraw/excalidraw';
import { ExcalidrawImperativeAPI } from '@excalidraw/excalidraw/types/types';
import axiosInstance from '../../../config/axiosConfig';

interface UseFrameToHTMLProps {
  excalidrawAPI: ExcalidrawImperativeAPI | null;
  featureId: string;
}

interface GenerateHTMLResult {
  htmlUrl: string;
  // Add any other properties that your backend returns
}

const useFrameToHTML = ({ excalidrawAPI, featureId }: UseFrameToHTMLProps) => {
  const [isGeneratingHtml, setIsGeneratingHtml] = useState(false);
  const [generatedHtmlUrl, setGeneratedHtmlUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const generateHtml = useCallback(async (): Promise<GenerateHTMLResult | null> => {
    setIsGeneratingHtml(true);
    setError(null);

    try {
      if (!excalidrawAPI) {
        throw new Error("Excalidraw API is not available");
      }

      // Export the current diagram as a blob
      const blob = await exportToBlob({
        elements: excalidrawAPI.getSceneElements(),
        appState: excalidrawAPI.getAppState(),
        files: excalidrawAPI.getFiles(),
        mimeType: "image/png",
        quality: 1,
      });

      // Create FormData to send both the image and the feature ID
      const formData = new FormData();
      formData.append('image', blob, 'diagram.png');
      formData.append('featureId', featureId);

      const endpoint = "/generate_diagram_to_html";
      const response = await axiosInstance.post(endpoint, formData);

      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result: GenerateHTMLResult = await response.data;
      console.log("HTML generated successfully:", result);
      setGeneratedHtmlUrl(result.htmlUrl);
      return result;

    } catch (error) {
      console.error("Error generating HTML:", error);
      setError(error instanceof Error ? error.message : 'An unknown error occurred');
      return null;
    } finally {
      setIsGeneratingHtml(false);
    }
  }, [excalidrawAPI, featureId]);

  return {
    generateHtml,
    isGeneratingHtml,
    generatedHtmlUrl,
    error,
  };
};

export default useFrameToHTML;