// app/javascript/controllers/tickets/tickets_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "status", "statusDropdown", "priority", "priorityDropdown", 
    "title", "titleInput", "ticketId", "description", "descriptionInput",
    "dueDate", "ticketSubform", "ticketableSelect"
  ]
  static values = {
    updateUrl: String,
    showUrl: String,
    baseStyle: String,
    useShortValues: Boolean,
    editMode: Boolean
  }

  connect() {
    console.log("TicketController connected")
    this.boundClickOutside = this.clickOutside.bind(this)
    document.addEventListener('click', this.boundClickOutside)
  }

  disconnect() {
    document.removeEventListener('click', this.boundClickOutside)
  }

  showTicket(event) {
    event.preventDefault()
    const url = this.showUrlValue
    fetch(url, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
    })
  }

  clickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.closeAllDropdowns()
    }
  }

  closeAllDropdowns() {
    if (this.hasStatusDropdownTarget) {
      this.statusDropdownTarget.classList.add('hidden')
    }
    if (this.hasPriorityDropdownTarget) {
      this.priorityDropdownTarget.classList.add('hidden')
    }
  }

  toggleStatusDropdown(event) {
    event.preventDefault()
    event.stopPropagation()
    this.statusDropdownTarget.classList.toggle('hidden')
  }

  togglePriorityDropdown(event) {
    event.preventDefault()
    event.stopPropagation()

    this.priorityDropdownTarget.classList.toggle('hidden')
  }

  changeStatus(event) {
    event.preventDefault()
    // event.stopPropagation()

    this.updateField('status', event.currentTarget.dataset.status)
    this.closeAllDropdowns()
  }

  changePriority(event) {
    event.preventDefault()
    this.updateField('priority', event.currentTarget.dataset.priority)
    this.closeAllDropdowns()
  }

  updateField(field, newValue) {
    const element = this[`${field}Target`]
    const originalValue = element.textContent.trim()

    fetch(this.updateUrlValue, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ticket: { [field]: newValue } })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Server response was not ok')
      }
      return response.json()
    })
    .then(data => {
      const returnedTicket = data.ticket
      
      const newValueKey = this.useShortValuesValue ? returnedTicket[`${field}_short`] : returnedTicket[`${field}`]
      const valueToDisplay = newValueKey.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())

      this.updateUI(element, valueToDisplay, newValue, field)
    })
    .then(data => {
      console.log(`${field} updated successfully`, field)
    })
    .catch(error => {
      console.error('Error:', error)
      this.updateUI(element, originalValue, field)
    })

    this[`${field}DropdownTarget`].classList.add('hidden')
  }

  updateUI(element, valueToDisplay, valueKey, field) {
    element.textContent = valueToDisplay
    console.log("Base Style: ", this.baseStyleValue)
    element.className = `${this.baseStyleValue} ${this.getColor(valueKey, field)} cursor-pointer`
  }

  formatValue(value, field) {
    if (field === 'priority') {
      const priorityMap = { low: 'P4', medium: 'P3', high: 'P2', urgent: 'P1' }
      return priorityMap[value] || value.charAt(0).toUpperCase() + value.slice(1)
    }
    return value
  }

  getColor(value, field) {
    const colors = {
      status: {
        backlog: 'bg-gray-200 text-gray-800 border border-gray-500',
        B: 'bg-gray-200 text-gray-800 border border-gray-500',
        todo: 'bg-gray-200 text-gray-800 border border-gray-500',
        TD: 'bg-gray-200 text-gray-800 border border-gray-500',
        open: 'bg-blue-200 text-blue-800 border border-blue-500',
        O: 'bg-blue-200 text-blue-800 border border-blue-500',
        reopened: 'bg-yellow-200 text-yellow-800 border border-yellow-500',
        R: 'bg-yellow-200 text-yellow-800 border border-yellow-500',
        in_progress: 'bg-blue-200 text-blue-800 border border-blue-500',
        IP: 'bg-blue-200 text-blue-800 border border-blue-500',
        in_review: 'bg-yellow-200 text-yellow-800 border border-yellow-500',
        IR: 'bg-yellow-200 text-yellow-800 border border-yellow-500',
        done: 'bg-green-200 text-green-800 border border-green-500',
        D: 'bg-green-200 text-green-800 border border-green-500',
        closed: 'bg-gray-700 text-white border border-gray-500',
        C: 'bg-gray-700 text-white border border-gray-500'
      },
      priority: {
        low: 'bg-green-200 text-gray-800 border border-green-500',
        P4: 'bg-green-200 text-gray-800 border border-green-500',
        medium: 'bg-yellow-200 text-gray-800 border border-yellow-500',
        P3: 'bg-yellow-200 text-gray-800 border border-yellow-500',
        high: 'bg-orange-200 text-gray-800 border border-orange-500',
        P2: 'bg-orange-200 text-gray-800 border border-orange-500',
        urgent: 'bg-red-200 text-gray-800 border border-red-500',
        P1: 'bg-red-200 text-gray-800 border border-red-500'
      }
    }
    return colors[field][value] || 'bg-gray-200 text-gray-800'
  }

  editTitle(event) {
    event.preventDefault()

    this.titleTarget.classList.add('hidden')
    console.log("this.titleInputTarget: ", this.titleInputTarget)
    this.titleInputTarget.classList.remove('hidden')
    this.titleInputTarget.value = this.titleTarget.textContent.trim()
    this.titleInputTarget.focus()
  }

  updateTitle() {
    const newTitle = this.titleInputTarget.value.trim()
    if (newTitle !== this.titleTarget.textContent.trim()) {
      console.log("updateTitle:", newTitle)
      this.saveTitle(newTitle)
    }
    this.titleTarget.classList.remove('hidden')
    this.titleInputTarget.classList.add('hidden')
  }

  handleTitleKeydown(event) {
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault()
      this.updateTitle()
    }
  }

  saveTitle(newTitle) {
    console.log("saveTitle")
    fetch(this.updateUrlValue, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Accept': 'text/vnd.turbo-stream.html'
      },
      body: JSON.stringify({
        ticket: {
          title: newTitle,
          foo: "bar"
        }
      })
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
    .catch(error => {
      console.error('Error:', error)
      this.titleTarget.textContent = this.titleTarget.dataset.originalTitle
    })
  }

  editDescription(event) {
    this.descriptionTarget.classList.toggle("hidden")
    this.descriptionInputTarget.classList.toggle("hidden")

    const editor = this.descriptionInputTarget.querySelector("trix-editor")
    editor.editor.setSelectedRange([0, editor.editor.getDocument().getLength()])
    editor.focus()
  }

  updateDescription(event) {
    event.preventDefault()
    const form = this.descriptionInputTarget.querySelector("form")
    const formData = new FormData(form)

    fetch(this.updateUrlValue, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      },
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        this.descriptionTarget.innerHTML = data.body.body
        this.descriptionTarget.classList.toggle("hidden")
        this.descriptionInputTarget.classList.toggle("hidden")
      } else {
        console.error("Failed to update description")
      }
    })
    .catch(error => {
      console.error("Error:", error)
    })
  }

  handleDescriptionKeydown(event) {
    if (event.key === "Escape") {
      this.descriptionTarget.classList.remove("hidden")
      this.descriptionInputTarget.classList.add("hidden")
    }
  }

  saveDescription(newDescription) {
    fetch(this.updateUrlValue, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ticket: { description: newDescription } })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Server response was not ok')
      }
      return response.json()
    })
    .then(data => {
      console.log('Description updated successfully')
    })
    .catch(error => {
      console.error('Error:', error)
      this.descriptionTarget.textContent = this.descriptionTarget.dataset.originalDescription
    })
  }

  updateDueDate(event) {
    const newDueDate = event.target.value
    
    fetch(this.updateUrlValue, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ticket: { due_date: newDueDate } })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Server response was not ok')
      }
      return response.json()
    })
    .then(data => {
      console.log('Due date updated successfully')
    })
    .catch(error => {
      console.error('Error:', error)
      event.target.value = event.target.defaultValue
    })
  }

  toggleEditMode(event) {
    event.preventDefault()
    this.editModeValue = !this.editModeValue
    this.updateEditModeUI()
  }

  updateEditModeUI() {
    if (this.editModeValue) {
      this.ticketSubformTarget.classList.remove('hidden')
    } else {
      this.ticketSubformTarget.classList.add('hidden')
      ere
    }
  }

  updateTicketable(event) {
    console.log("updateTicketable")
    const newTicketableId = event.target.value;
    const ticketId = this.element.dataset.ticketId;

    fetch(this.updateUrlValue, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Accept': "text/vnd.turbo-stream.html"
      },
      body: JSON.stringify({ 
        ticket: { 
          ticketable_id: newTicketableId,
          ticketable_type: newTicketableId ? 'Feature' : null
        } 
      })
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Server response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Ticketable updated successfully', data);
    })
    .catch(error => {
      console.error('Error:', error);
      this.ticketableSelectTarget.value = this.ticketableSelectTarget.dataset.originalValue;
    });
  }

  updateTicket() {
    console.log("updateTicket: ", updateTicket)
    const formData = new FormData();

    if (this.titleInputTarget.value.trim()) {
      formData.append('ticket[title]', this.titleInputTarget.value.trim());
    }
    if (this.ticketableSelectTarget.value) {
      formData.append('ticket[ticketable_id]', this.ticketableSelectTarget.value);
      formData.append('ticket[ticketable_type]', 'Feature');
    }
    if (this.element.dataset.ticketId) {
      formData.append('ticket[ticket_id]', this.element.dataset.ticketId);
    }
    if (this.element.dataset.type) {
      formData.append('ticket[type]', this.element.dataset.type);
    }
    if (this.descriptionInputTarget.value.trim()) {
      formData.append('ticket[description]', this.descriptionInputTarget.value.trim());
    }
    if (this.statusDropdownTarget.value) {
      formData.append('ticket[status]', this.statusDropdownTarget.value);
    }
    if (this.priorityDropdownTarget.value) {
      formData.append('ticket[priority]', this.priorityDropdownTarget.value);
    }
    if (this.dueDateTarget.value) {
      formData.append('ticket[due_date]', this.dueDateTarget.value);
    }
    if (this.element.dataset.archived) {
      formData.append('ticket[archived]', this.element.dataset.archived);
    }
    if (this.element.dataset.position) {
      formData.append('ticket[position]', this.element.dataset.position);
    }
    if (this.element.dataset.body) {
      formData.append('ticket[body]', this.element.dataset.body);
    }

    fetch(this.updateUrlValue, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(Object.fromEntries(formData))
    })
    .then(response => response.json())
    .then(data => {
      Turbo.renderStreamMessage(data.html)
    })
    .catch(error => {
      console.error('Error:', error);
      this.titleTarget.textContent = this.titleTarget.dataset.originalTitle;
    });
  }
}