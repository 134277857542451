// app/javascript/axiosConfig.ts
import axios from 'axios';

const axiosInstance = axios.create();

export const setupCSRF = (csrfToken: string) => {
  axiosInstance.interceptors.request.use((config) => {
    if (config.method !== 'GET') {
      config.headers['X-CSRF-Token'] = csrfToken;
    }
    return config;
  });
};

export default axiosInstance;