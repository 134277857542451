import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidemenu"
export default class extends Controller {
  connect() {
    let sidebar = document.getElementById("main_sidemenu")
    sidebar.setAttribute('aria-expanded', this.getCookie(sidebar.id))
  }

  toggle() {
    // console.log("toggle!")
    let sidebar = document.getElementById("main_sidemenu")
    let expanded = sidebar.getAttribute('aria-expanded')
    let boolValue = (expanded.toLowerCase() === "true")

    if (boolValue) {
      sidebar.setAttribute('aria-expanded','false')
    } else {
      sidebar.setAttribute('aria-expanded','true')
    }
    // console.log("expanded: ", !boolValue)
    this.setCookie(sidebar.id, !boolValue, 365)
  }

  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  
  getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  
  checkCookie(cname, cvalue) {
    let user = this.getCookie(cname);
    if (user != "") {
      return user
    } else {
      user = cvalue
      this.setCookie(cname, user, 365);
    }
  }
}
