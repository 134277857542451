// app/javascript/components/Excalidraw/stores/rootReducer.ts

import { combineReducers } from '@reduxjs/toolkit';
import { framesReducer } from './slices/framesSlice';

const rootReducer = combineReducers({
  frames: framesReducer,
  // Add other reducers here as needed
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;