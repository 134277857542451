// app/javascript/controllers/tickets/kanban/modal_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "modalTitle", "modalContent"]
  static values = {
    currentContentKey: String,
    lastFetchedUrl: String
  }

  connect() {
    // console.log("Modal controller connected")
    this.hide()
    this.modalTarget.addEventListener('click', this.handleOutsideClick.bind(this))
    document.addEventListener('keydown', this.handleKeyDown.bind(this))
  }

  open(event) {
    event.preventDefault()
    const title = event.currentTarget.dataset.title || "Ticket Details"
    const fetchUrl = event.currentTarget.dataset.fetchUrl
    this.modalTitleTarget.textContent = title
    this.show()

    if (fetchUrl === this.lastFetchedUrlValue) return

    fetch(fetchUrl)
      .then(response => response.text())
      .then(data => {
        this.lastFetchedUrlValue = fetchUrl
        
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, "text/html");
        const mainContent = doc.querySelector("turbo-frame#main_content");
        if (mainContent) {
          this.modalContentTarget.innerHTML = `<turbo-frame id="main_content">${mainContent.innerHTML}</turbo-frame>`;
        } else {
          console.error("Error: main_content frame not found in the fetched data");
        }
      })
      .catch(error => console.error("Error fetching content:", error))
  }

  close() {
    this.hide()
  }

  show() {
    this.modalTarget.classList.remove("hidden")
  }

  hide() {
    this.modalTarget.classList.add("hidden")
  }

  handleOutsideClick(event) {
    if (event.target === this.modalTarget) {
      this.close()
    }
  }

  handleKeyDown(event) {
    if (event.key === 'Escape') {
      this.close()
    }
  }
}